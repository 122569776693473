import React, {useEffect, useState} from "react";
import StandardAppContainer from "./Standard/StandardAppContainer";
import Main from "./pages/Main";
import {BrowserRouter as Router, Route,} from "react-router-dom";
import HeaderUserManualButton from "./components/HeaderUserManualButton";
import CurrentNFT from "./pages/CurrentNFT";
import {useWeb3React} from "@web3-react/core";
import {useAllocationMarketplaceContract} from "./hooks/useMarketplaceContract";
import ProjectContext from "./utils/ProjectsContext";
import {NFT, ProjectsDict} from "./types";
import {HeaderButton} from "./Standard/components/WalletConnector";
import ProjectCollection from "./pages/ProjectCollection";
import Collection from "./pages/Collection";
import CollectionButton from "components/ConnectorButtons/Collection";
import DisconnectButton from "components/ConnectorButtons/Disconnect";
import WalletButton from 'components/ConnectorButtons/Wallet';
import MMPROLabs from "./pages/MMPROLabs";
import {NavItems} from "./Standard/types/NavItems";
import {NavItemsEnum} from "./types/NavItem";
import NavItem from "./components/Navigation";
import CurrentLabsNft from "./pages/CurrentLabsNft";
import NftsForSale from "./pages/NftsForSale"

export const App = () => {
	const [allProjects, setAllProjects] = useState<ProjectsDict>({})
	const [tokens, setTokens] = useState()

	const {account} = useWeb3React()

	const marketplaceContract = useAllocationMarketplaceContract()

	async function getAllProjects() {
		let newProjects: ProjectsDict = {}
		for (let i = 0; i < 99999; i++) {
			let newProject: NFT
			try {
				newProject = {...(await marketplaceContract.methods.projects(i).call()), projectId: i}
			} catch (e) {
				break
			}
			newProjects[newProject.name] = {...newProject}
		}

		setAllProjects(newProjects)
	}

	useEffect(() => {
		getAllProjects()
	}, [account])

	const buttons: HeaderButton[] = [
		<WalletButton/>,
		<CollectionButton/>,
		<DisconnectButton/>
	]

	const navItems: NavItems[] = [
		<NavItem title={NavItemsEnum.STORE} route={'/'} isDecorated={false}/>,
		<NavItem title={NavItemsEnum.LAB} route={'/mmpro-labs'} isDecorated/>,
		<NavItem title={NavItemsEnum.MARKETPLACE} route={'/marketplace'} isDecorated={false}/>,
	]

	return (
		<Router>
			<StandardAppContainer
				headerNavigation={navItems}
				logoHref={'https://marketmaking.pro'}
				locales={['en', 'ja']}
				isDarkBG={false}
				version={"1.0.5"}
				connectorButtons={buttons}
				headerButtons={[
					<HeaderUserManualButton key={"header-user-manual-button"}/>
				]}
			>
				<ProjectContext.Provider value={{setProjects: setAllProjects, projects: allProjects, tokens, setTokens}}>
					<Route component={Main} path={'/'} exact/>
					<Route component={ProjectCollection} path={'/projects/:id'} exact/>
					<Route component={Collection} path={'/collection'} exact/>
					<Route component={CurrentNFT} path={'/projects/:id/:nftId'} exact/>
					<Route component={MMPROLabs} path={'/mmpro-labs'} exact/>
					<Route component={CurrentLabsNft} path={'/collection/:id'} exact/>
					<Route component={NftsForSale} path={'/marketplace'} exact/>
				</ProjectContext.Provider>
			</StandardAppContainer>
		</Router>
	);
};
