import React from "react";
import NFTMarketplaceBackground from '../../icons/NFTMarketplaceBackground';
import Text from '../Text';
import styled from "styled-components";

type SubHeaderPropType = {
  title: string
  subtitle?: string
}

const SubHeaderDefaultProps = {}

const FlexWrapper = styled.div`
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: 100%;
`

const TextFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  z-index: 1;
`

const BackgroundImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  z-index: 0;
`

const SubtitleWrapper = styled.div`
  text-align: center;
  padding-left: 80px;
  padding-right: 80px;

  @media screen and (max-width: 720px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const SubHeader = (props: SubHeaderPropType) => {
  const {title, subtitle} = props;
  return (
    <FlexWrapper>
      <TextFlexWrapper>
        <Text fontWeight={700} fontSize={45} color={'#33CC66'}>{title.split(" ")[0]}</Text>
        <Text fontWeight={700} fontSize={45} color={'#181833'}>{title.split(" ")[1]}</Text>
      </TextFlexWrapper>
      <SubtitleWrapper>
        <Text fontWeight={600} fontSize={25} color={'#33CC66'}>{subtitle}</Text>
      </SubtitleWrapper>
    </FlexWrapper>
  )
};

SubHeader.defaultProps = SubHeaderDefaultProps

export default SubHeader