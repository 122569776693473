import React, {useContext} from "react";
import LocaleContext from "../../Standard/LocaleContext";
import NFTProjectTile from "../NFTProjectTile";
import { Project } from "../../types";
import styled from "styled-components";

type CurrentProjectWrapperPropType = {
  children: React.ReactNode;
  project: Project | undefined
}

const CurrentProjectWrapperDefaultProps = {}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #E8E8EB;
  border-radius: 16px;
`

const NFTSContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: max-content;
  background: #fff;
  border-radius: 0 16px 16px 0;
  border-left: 1px solid #E8E8EB;

  @media screen and (max-width: 720px) {
    width: 100%;
    border: 1px solid rgba(24, 24, 51, 0.1);
    border-radius: 16px;
  }
`

const CurrentProjectWrapper = (props: CurrentProjectWrapperPropType) => {
  const {locale} = useContext(LocaleContext)
  const {children, project} = props

  return (
    <Container>
      <NFTProjectTile
        project={project}
        isProjectCollection
      />
      <NFTSContainer>
        {children}
      </NFTSContainer>
    </Container>
  )
};

CurrentProjectWrapper.defaultProps = CurrentProjectWrapperDefaultProps

export default CurrentProjectWrapper