import React, {useContext} from 'react';
import styled from "styled-components";
import {localized} from "Standard/utils/localized";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import Text from "../../Text";
import Card from './Card'
import FirstCardIcon from 'components/MMPROLabs/images/FirstCardIcon.svg'
import SecondCardIcon from 'components/MMPROLabs/images/SecondCardIcon.svg'
import ThirdCardIcon from 'components/MMPROLabs/images/ThirdCardIcon.svg'
import FourthCardIcon from 'components/MMPROLabs/images/FourthCardIcon.svg'

const Container = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;
  overflow: hidden;
  
  @media screen and (max-width: 1300px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.76) 100%);
  backdrop-filter: blur(81px);
  width: 100%;
  height: 100%;
  z-index: 2;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`

const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: 60px;
  z-index: 4;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`

const StyledImage = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;

  @media screen and (max-width: 1300px) {
    width: 280px;
  }
`

const FirstCardImage = styled(StyledImage)`
  bottom: 0;
`

const SecondCardImage = styled(StyledImage)`
  bottom: 28px;
`

const ThirdCardImage = styled(StyledImage)`
  bottom: 0;
`

const FourthCardImage = styled(StyledImage)`
  bottom: 10px;
`

const RightCircle = styled.div`
  position: absolute;
  bottom: 0;
  left: 65px;
  width: 531px;
  height: 531px;
  background: linear-gradient(228.62deg, #5790FF 12.13%, #A5FDC3 94.47%);
  opacity: 0.4;
  z-index: 1;
  border-radius: 50%;

  @media screen and (max-width: 1200px) {
    top: 0;
    right: 0;
  }
`

const MiddleCircle = styled.div`
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(228.62deg, #5790FF 12.13%, #A5FDC3 94.47%);
  opacity: 0.3;
  bottom: 0;
`

const LeftCircle = styled.div`
  position: absolute;
  width: 472px;
  height: 472px;
  right: 65px;
  bottom: 0;
  background: linear-gradient(133.46deg, #ADE6C0 14.79%, #A5FDC3 103.42%);
  opacity: 0.4;
`

const HowItWorks = () => {
  const {locale} = useContext(LocaleContext)
  return (
    <Container>
      <Blur />
      <RightCircle />
      <MiddleCircle />
      <LeftCircle />
      <TitleWrapper>
        <Text
          fontWeight={700}
          fontSize={32}
          color={'#33CC66'}
        >
          {localized(texts.howItWorks, locale)}
        </Text>
      </TitleWrapper>
      <CardsWrapper>
        <Card cardNumber={1}>
          <Text fontWeight={600} fontSize={20}>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'}>{localized(texts.buy, locale)} </Text>
            {localized(texts.aLotOfTokens, locale)}
          </Text>
          <FirstCardImage>
            <img src={FirstCardIcon} />
          </FirstCardImage>
        </Card>
        <Card cardNumber={2}>
          <Text fontWeight={600} fontSize={20}>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'}>{localized(texts.lock, locale)} </Text>
            {localized(texts.lockText, locale)}
          </Text>
          <SecondCardImage>
            <img src={SecondCardIcon} />
          </SecondCardImage>
        </Card>
        <Card cardNumber={3}>
          <Text fontWeight={600} fontSize={20}>
            {localized(texts.get, locale)}
            <Text fontWeight={600} fontSize={20} color={'#33CC66'}> {localized(texts.exclusiveNft, locale)}</Text>
          </Text>
          <ThirdCardImage>
            <img src={ThirdCardIcon} />
          </ThirdCardImage>
        </Card>
        <Card cardNumber={4}>
          <Text fontWeight={600} fontSize={20}>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'}>{localized(texts.shareRevenue, locale)} </Text>
            {localized(texts.shareText, locale)}
          </Text>
          <FourthCardImage>
            <img src={FourthCardIcon} />
          </FourthCardImage>
        </Card>
      </CardsWrapper>
    </Container>
  );
};

export default HowItWorks;