import React, {useContext} from 'react';
import styled, {css} from "styled-components";
import {localized} from "../../../Standard/utils/localized";
import texts from "./localization";
import Text from "../../Text";
import ReferalProgramLogo from 'components/MMPROLabs/images/referalProgramLogo.svg'
import WowSummitLogo from 'components/MMPROLabs/images/wowLogoDecoration.svg'
import ChatLogo from 'components/MMPROLabs/images/chatLogoDecoration.svg'
import LocaleContext from "../../../Standard/LocaleContext";

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 90px;
  
  @media screen and (max-width: 1300px) {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.76) 100%);
  backdrop-filter: blur(81px);
  width: 100%;
  height: 100%;
  z-index: 2;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin-bottom: 80px;

  @media screen and (max-width: 1300px) {
    text-align: center;
    margin-bottom: 30px;
  }
`

const GradientCircle = styled.div<{width: number, height: number, top?: number, left?: number, right?: number, bottom?: number, background: string}>`
  position: absolute;
  border-radius: 50%;
  background: ${p => p.background};
  width: ${p => p.width}px;
  height: ${p => p.height}px;
  opacity: 0.4;

  ${({ top }) => top && css`
    top: ${top}px;
  `};

  ${({ left }) => left && css`
    left: ${left}px;
  `};

  ${({ right }) => right && css`
    right: ${right}px;
  `};

  ${({ bottom }) => bottom && css`
    bottom: ${bottom}px;
  `};
`

const CardsWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TopCardTile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(51, 204, 102, 0.1);
  border-radius: 20px 20px 0 0;
  width: 420px;
  height: 200px;
  overflow: hidden;

  @media screen and (max-width: 1300px) {
    width: 280px;
  }
`

const BottomCardTile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 416px;
  height: max-content;
  padding: 50px;
  background: #fff;
  border-radius: 0 0 20px 20px;
  
  @media screen and (max-width: 1300px) {
    width: 280px;
    padding: 25px;
  }
`

const Opportunities = () => {
  const {locale} = useContext(LocaleContext)

  return (
    <Container>
      <Blur />
      <GradientCircle
        height={470}
        width={470}
        background={'linear-gradient(133.46deg, #ADE6C0 14.79%, #A5FDC3 103.42%)'}
        bottom={-100}
        left={-100}
      />
      <GradientCircle
        height={470}
        width={470}
        background={'linear-gradient(133.46deg, #ADE6C0 14.79%, #A5FDC3 103.42%)'}
        top={-80}
        left={150}
      />
      <GradientCircle
        height={470}
        width={470}
        background={'linear-gradient(133.46deg, #ADE6C0 14.79%, #A5FDC3 103.42%)'}
        bottom={-100}
        right={-100}
      />
      <GradientCircle
        height={530}
        width={530}
        background={'linear-gradient(228.62deg, #5790FF 12.13%, #A5FDC3 94.47%);'}
        bottom={-50}
        left={500}
      />
      <GradientCircle
        height={370}
        width={370}
        background={'linear-gradient(228.62deg, #5790FF 12.13%, #A5FDC3 94.47%);'}
        top={40}
        right={400}
      />
      <GradientCircle
        height={530}
        width={530}
        background={'linear-gradient(228.62deg, #5790FF 12.13%, #A5FDC3 94.47%);'}
        top={40}
        right={40}
      />
      <TitleWrapper>
        <Text
          fontWeight={700}
          fontSize={32}
          color={'#33CC66'}
          adaptiveFontSize={25}
        >
          {localized(texts.extraOpportunities, locale)}
        </Text>
      </TitleWrapper>
      <CardsWrapper>
        <CardWrapper>
          <TopCardTile>
            <img src={ReferalProgramLogo} alt=""/>
          </TopCardTile>
          <BottomCardTile>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'} textTransform={'uppercase'}>{localized(texts.referal, locale)}</Text>
            <Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51, .7)'}>{localized(texts.earn, locale)}</Text>
          </BottomCardTile>
        </CardWrapper>
        <CardWrapper>
          <TopCardTile>
            <img src={ChatLogo} alt=""/>
          </TopCardTile>
          <BottomCardTile>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'} textTransform={'uppercase'}>
              {localized(texts.privateChat, locale)}
            </Text>
            <Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51, .7)'}>
              {localized(texts.privateChat2, locale)}<strong>{localized(texts.privateChat3, locale)}</strong>
            </Text>
          </BottomCardTile>
        </CardWrapper>
        <CardWrapper>
          <TopCardTile>
            <img src={WowSummitLogo} alt=""/>
          </TopCardTile>
          <BottomCardTile>
            <Text fontWeight={600} fontSize={20} color={'#33CC66'} textTransform={'uppercase'}>
              {localized(texts.invites, locale)}
            </Text>
            <Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51, .7)'}>
              {localized(texts.wow, locale)}<strong>{localized(texts.intBuisnes, locale)}</strong>
            </Text>
          </BottomCardTile>
        </CardWrapper>
      </CardsWrapper>
    </Container>
  );
};

export default Opportunities;