import React from 'react';
import styled from "styled-components";

type CardPropsType = {
  cardNumber: number
  children: React.ReactNode | React.ReactNode[]
}

const CardWrapper = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background: #fff;
  padding-top: 70px;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 20px;
  z-index: 4;
  
  @media screen and (max-width: 1300px) {
    width: 280px;
    height: 280px;
  }
`

const Number = styled.div`
  position: absolute;
  font-weight: 600;
  font-size: 64px;
  line-height: 141.8%;
  color: #33CC66;
  opacity: 0.3;
  left: 50%;
  transform: translateX(-50%);
  top: -45px;
`

const Card = (props: CardPropsType) => {
  const {cardNumber, children} = props;
  return (
    <CardWrapper>
      <Number>{cardNumber}</Number>
      {children}
    </CardWrapper>
  );
};

export default Card;