import React, {useContext, useState} from 'react';
import Text from "components/Text";
import {
  JustifyCenterColumn,
  RowCentered,
  SpaceBetweenRow
} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";

import bottomDecoration from '../images/BottomDecoration.svg'
import manDecoration from '../images/bottomManDecoration.svg'
import cube from '../images/cube.svg'
import cubeField from '../images/cubeFilled.svg'
import LabsButton from "../LabsButton";
import LabsModal from "../LabsModal";
import Modal from "Standard/components/Modal";
import {localized} from "../../../Standard/utils/localized";
import texts from "./localization";
import LocaleContext from "../../../Standard/LocaleContext";
import {instance} from "../../../api";

const Container = styled(RowCentered)`
  min-height: 800px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  
  @media screen and (max-width: 1300px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Content = styled(SpaceBetweenRow)`
  overflow: hidden;
  position: relative;
  height: 526px;
  background: white;
  max-width: 1088px;
  width: 100%;
  border-radius: 30px;
  
  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
  }
`

const Left = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 100%;
  background: #fff;
  padding: 150px 50px;
  box-shadow: 0px 0px 73px rgba(0, 0, 0, 0.08);
  
  @media screen and (max-width: 1300px) {
    padding: 20px 20px;
    width: 280px;
    border-radius: 30px 30px 0 0;
  }
`

const ManDecoration = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`

const CubeBottom = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`

const CubeTop = styled.img`
  position: absolute;
  left: 0;
  top: 0;

  @media screen and (max-width: 1200px){
    display: none;
  }
`

const NftTile = styled.img`
  width: 300px;
  height: 338px;
  border-radius: 23px;
`

const Right = styled(JustifyCenterColumn)`
  position: relative;
  width: 50%;
  height: 100%;
  background: linear-gradient(174deg, rgb(67, 221, 90) 0%, rgb(63, 155, 91) 100%);
  box-shadow: 0px 0px 73px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 30px 30px;
  
  @media screen and (max-width: 1200px){
    width: 280px;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  background: #33CC66;
  border-radius: 40px;
  cursor: pointer;
  outline: none !important;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  margin-top: 35px;
`

const BottomBlock = () => {
  const {locale} = useContext(LocaleContext)
  const [isModalActive, setIsModalActive] = useState(false)
  const [totalSupply, setTotalSupply] = useState<undefined | number>(undefined)

  const getTotalSupplyLabs = async () => {
    try {
      const response = await instance.get('/labs/nft/supply')
      setTotalSupply(response.data.data.totalSupply)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Container>
      <Content>
        <Left>
          <Text textTransform={'uppercase'} fontSize={36} fontWeight={700} color={'#33CC66'} adaptiveFontSize={32}>{localized(texts.mintHere, locale)}</Text>
          {totalSupply && totalSupply < 100 ?
            <Button onClick={() => setIsModalActive(true)}>{localized(texts.mint, locale)}</Button>
            :
            <Text
              fontWeight={700}
              fontSize={32}
              color={'#33CC66'}
              adaptiveFontSize={12}
            >
              SOLD OUT
            </Text>
          }
        </Left>
        <Right gap={32}>
          <ManDecoration src={manDecoration}/>
          <CubeBottom src={cubeField}/>
          <CubeTop src={cube}/>
        </Right>
      </Content>
      {
        isModalActive &&
        <Modal onClose={setIsModalActive} title={"test"}>
          <LabsModal />
        </Modal>
      }
    </Container>
  );
};

export default BottomBlock;