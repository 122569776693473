import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default (props) => {
  const {height, width} = props;
  return (
    <svg width={width} height={height} viewBox="0 0 56 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5 64.975C27.6 64.975 27.7 65.075 27.8 65.075H27.9H28C28.1 65.075 28.2 65.075 28.3 64.975L55.5 48.875C55.7 48.775 55.8 48.575 55.8 48.375V16.575V16.375C55.8 16.175 55.7 15.975 55.5 15.875C55.4 15.775 55.2 15.775 55.1 15.875L28.2 0.075C28 -0.025 27.8 -0.025 27.6 0.075L0.700012 15.875C0.600012 15.775 0.399988 15.775 0.299988 15.875C0.0999878 15.975 0 16.175 0 16.375V16.575V48.375C0 48.575 0.0999878 48.775 0.299988 48.875L27.5 64.975ZM54.8 48.075L28.6 63.475V32.775L48.3 21.075L54.7 17.475V48.075H54.8ZM27.9 1.275L54 16.575L47.7 20.275L27.9 31.375L8.40002 20.475L1.79999 16.575L27.9 1.275ZM1.09998 17.575L7.5 21.175L27.2 32.775V63.475L1.09998 48.075V17.575Z"
        fill="white"/>
    </svg>
  );
};
