export default {
  amount: {
    en: 'Amount of MMPro to stake',
    ja: ''
  },
  lockUpPeriod: {
    en: 'Lock up period',
    ja: ''
  },
  month1: {
    en: '1 MONTH: 10% APP',
    ja: ''
  },
  month6: {
    en: '6 MONTH: 20% APP',
    ja: ''
  },
  month12: {
    en: '12 MONTH: 30% APP',
    ja: ''
  },
  changePeriod: {
    en: 'Change period',
    ja: ''
  },
  Approve: {
    en: 'Approve',
    ja: ''
  },
  Mint: {
    en: 'Mint',
    ja: ''
  },
  dontHave: {
    en: 'You don\'t have enough MMPRO',
    ja: ''
  },
  connectWallet: {
    en: 'Please connect wallet',
    ja: ''
  },
}