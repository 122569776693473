import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import './index.css'
import {JustifyStartColumn} from "../../Standard/styles/GlobalStyledComponents";
import ButtonV2 from "../../Standard/components/ButtonV2";
import styled from "styled-components";
import {NftForSale} from "types";
import {useNFTLabsContract} from "../../hooks/useLabsContract";
import NotificationContext from "../../Standard/utils/NotificationContext";
import {useHistory} from "react-router-dom";
import NotificationIcon from "../../Standard/icons/notificationIcon";
import {useWeb3React} from "@web3-react/core";
import Spinner from "../../Standard/components/Spinner";
import Text from "components/Text";
import fromExponential from "from-exponential";
import {getIvendContract, getMMProLabsContract} from "../../utils/getAddress";
import {useMMProContract, useWeb3} from "../../Standard/hooks/useCommonContracts";
import {useBalanceOfBUSD, useBalanceOfMMPRO} from "../../hooks/useBalance";
import {useIvendContract} from "../../hooks/useIvendContract";

type NftForSaleCardPropType = {
	nft: NftForSale
	markup: number
}

const NftForSaleCardDefaultProps = {}

const StyledNftLabsImg = styled.img`
  max-width: 350px;
  width: 100%;
  margin-bottom: 10px;
`

const NFTItemWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  background: #fff;
  padding: 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
`

const UserNftTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 10px;
  padding-right: 12px;
  padding-left: 12px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
`

const NftForSaleCard = (props: NftForSaleCardPropType) => {
	const {locale} = useContext(LocaleContext)
	const {nft, markup} = props
	const web3 = useWeb3()
	const isIvend = nft.type && nft.type.includes('ivend')
	const ivendContract = useIvendContract()


	const {account} = useWeb3React()
	const MMPROLabsContract = useNFTLabsContract()
	const MMProContract = useMMProContract();
	const {balance, updateBalance} = useBalanceOfBUSD()
	const {balance: balanceMMpro, updateBalance: updateMMproBalance} = useBalanceOfMMPRO()
	const notificationContext = useContext(NotificationContext)
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)
	const [allowance, setAllowance] = useState<string>("");
	const [isApproveLoading, setIsApproveLoading] = useState(false);
	console.log(markup)
	const buyNft = async (tokenId: number) => {
		const nftPrice = +nft.basePrice / 10 ** 18 + (+nft.basePrice / 10 ** 18) * markup
		const userBalance = +balanceMMpro / 10 ** 18
		const isBalanceValid = userBalance > nftPrice

		if (!isBalanceValid) {
			notificationContext.displayNotification(
				localized(texts.notificationInsufficientTitle, locale),
				'',
				<NotificationIcon/>
			)
			return
		}

		setIsLoading(true)
		const gasPrice = await web3.eth.getGasPrice();
		try {
			await MMPROLabsContract.methods.buyNft(tokenId)
				.send({from: account, gasPrice: gasPrice})
				.once('receipt', () => {
					notificationContext.displayNotification(
						`Purchase was successful`,
						'',
						<NotificationIcon/>
					)
					history.push('/collection')
				});
		} catch (e) {
			notificationContext.displayNotification(
				`Something went wrong`,
				'',
				<NotificationIcon/>
			)
			setIsLoading(false)
			console.log(e)
		}
	}

	const approve = async () => {

		if (isApproveLoading) {
			return;
		}
		const ALLOWANCE = web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1));
		const gasPrice = await web3.eth.getGasPrice();
		setIsApproveLoading(true);
		try {
			await MMProContract.methods.approve((isIvend ? getIvendContract() : getMMProLabsContract()), ALLOWANCE)
				.send({from: account, gasPrice: gasPrice})
				.once("receipt", () => {
					updateAllowance();
					setIsApproveLoading(false);
				});

		} catch (e) {
			setIsApproveLoading(false);
		}
	};
	const getAllowance = async (): Promise<string> => {
		return await MMProContract
			.methods
			.allowance(account, (isIvend ? getIvendContract() : getMMProLabsContract()))
			.call();
	};
	const updateAllowance = async () => {
		const newAllowance = await getAllowance();
		setAllowance(newAllowance);
	};

	useEffect(() => {
		updateAllowance();
		updateBalance()
		updateMMproBalance()
	}, [account])

	const isApprovalRequired = parseInt(allowance) <= +(+nft.basePrice / 10 ** 18).toFixed(0);

	return (
		<NFTItemWrapper>
			{nft.isUserNft && <UserNftTile>Your NFT</UserNftTile>}
			<JustifyStartColumn gap={10}>
				<StyledNftLabsImg src={`/images/${isIvend ? `ivendNfts/Tier${+(nft.tier || 0) + 1}` : 'LabsCover'}.png`}
				                  alt=""/>
				<Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51, .3)'}>Selling price</Text>
				{isIvend ?
					<Text fontWeight={600} fontSize={24}
					      color={'rgba(24, 24, 51)'}>{(+(nft.basePrice || `0`) / 10 ** 18 + (+(nft.basePrice || `0`) / 10 ** 18) * markup).toFixed(0)} MMPRO</Text>
					:
					<Text fontWeight={600} fontSize={24}
					      color={'rgba(24, 24, 51)'}>{(+nft.basePrice / 10 ** 18 + (+nft.basePrice / 10 ** 18) * markup).toFixed(0)} MMPRO</Text>
				}
				{!nft.isUserNft &&
          <ButtonV2
            isValid
            onClick={isApprovalRequired ? approve : () => buyNft(+nft.tokenId)}
          >
						{
							isLoading || isApproveLoading ?
								<Spinner color={'#fff'} size={25}/>
								:
								<span>{isApprovalRequired ? "Approve" : "Buy"}</span>
						}
          </ButtonV2>
				}
			</JustifyStartColumn>
		</NFTItemWrapper>
	)
};

NftForSaleCard.defaultProps = NftForSaleCardDefaultProps

export default NftForSaleCard