export default {
  extraOpportunities:{
    en: 'EXTRA OPPORTUNITIES',
    ja: '',
  },
  referal:{
    en: 'Exclusive referral program for marketmaking',
    ja: '',
  },
  earn:{
    en: 'You can earn up to 30% commission from your referrals order value',
    ja: '',
  },
  privateChat:{
    en: 'Access to the private chat with Discussions and recommendations on the top-rated projects',
    ja: '',
  },
  privateChat2:{
    en: 'Access to the private chat with Discussions and recommendations on the top-rated projects and investment opportunities in crypto and blockchain, including ',
    ja: '',
  },
  privateChat3:{
    en: 'Real-Time Analysis Tools, Expert Market Commentary & Market-Leading Educational Resources',
    ja: '',
  },
  invites:{
    en: 'VIP INVITATION AND PARTICIPATION ONCE A YEAR. IN ANY COUNTRY',
    ja: '',
  },
  wow:{
    en: 'WOW Summit is a global series of blockchain and web3 conferences that are taking place in 5+ countries across the world for ',
    ja: '',
  },
  intBuisnes:{
    en: 'international businesses, government authorities, C-level, tech visionaries, investors, and developers',
    ja: '',
  },
}