import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {ProjectToken} from "../../types";
import styled from "styled-components"
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import { API_URL } from "../../api";

type NFTTilePropType = {
  token: ProjectToken | undefined,
  projectId: string
}

const NFTTileWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: max-content;
  margin-bottom: 16px;
  clip-path: content-box;
  border: 1px solid #E8E8EB;
`

const NFTProjectLimit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 10px;
  padding-right: 6px;
  padding-left: 6px;
  height: 30px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  color: #fff;
`

const NFTProjectArtwork = styled.img`
  width: 240px;
  height: 220px;
  overflow: hidden;
  object-fit: cover;
  transition: 0.3s;
  display: block;
  z-index: 0;
  cursor: pointer;
  transform: scale(1);

  &:hover {
    transform: scale(1.2)
  }

  @media screen and (max-width: 900px){
    transform: scale(1) !important;
  }
`

const Price = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: rgba(24,24,51, .5);
  border-radius: 8px;
  height: 20px;
  margin-bottom: 5px;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
`

const NFTTileSimple = (props: NFTTilePropType) => {
  const {token, projectId} = props
  const history = useHistory();
  const {locale} = useContext(LocaleContext)

  return (
    <FlexWrapper>
      <NFTTileWrapper
        onClick={token ? () => history.push(`/projects/${projectId}/${token.nftId}`): ()=>{}}
        className={`${!token ? 'skeleton': ''}`}
      >
        <NFTProjectArtwork src={`${API_URL}/${token?.imagePath}`} />
        <NFTProjectLimit>
          {`${token ? (+token.allocationLimit - +token.allocationAmount): 0} 
          ${localized(texts.left, locale)} `}
        </NFTProjectLimit>
      </NFTTileWrapper>
      <Price className={`${!token ? 'skeleton': ''}`}>{`${localized(texts.allocate, locale)} ${token?.maxAllocation} USDT`}</Price>
      <Price className={`${!token ? 'skeleton': ''}`}>{`${localized(texts.basePrice, locale)} ${token?.price} USDT`}</Price>
    </FlexWrapper>
  )
};

export default NFTTileSimple