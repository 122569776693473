import React, {useContext, useState} from 'react';
import {RowCentered, JustifyCenterColumn} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import './index.css';
import Text from 'components/Text'
import ButtonV2 from "Standard/components/ButtonV2";
import {useHistory, useParams} from "react-router-dom";
import {useNFTLabsContract} from "../../../hooks/useLabsContract";
import NotificationContext from "../../../Standard/utils/NotificationContext";
import NotificationIcon from "../../../Standard/icons/notificationIcon";
import {useWeb3React} from "@web3-react/core";
import Spinner from "../../../Standard/components/Spinner";

type ChangeStakePeriodModalPropType = {
  reward: number
  endOfStaking: string
  lockId: number
}

const ChooseTierBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px 30px 30px 30px;
  background: #fff;
  width: 700px;
  height: max-content;
  padding: 45px;
  gap: 15px;

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
    padding: 30px;
  }
`

const NftInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin-top: 20px;
  
  @media screen and (max-width: 1200px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ChangeStakePeriodModal = (props: ChangeStakePeriodModalPropType) => {
  const {lockId, endOfStaking, reward} = props

  const {account} = useWeb3React()

  const [isLoading, setIsLoading] = useState(false)

  const MMPROLabsContract = useNFTLabsContract()
  const notificationContext = useContext(NotificationContext)

  const params: {id: string} = useParams()
  const history = useHistory()
  const getStakePercent = () => {
    if (lockId === 0) {
      return '1 month: 10%'
    } else if (lockId === 1) {
      return '6 month: 20%'
    } else {
      return '12 month: 30%'
    }
  }
  const unlock = async () => {
    setIsLoading(true)
    try {
      await MMPROLabsContract.methods.unlock(+params.id).send({from: account}).once('receipt', () => {
        notificationContext.displayNotification(
          `Unlock was successful`,
          '',
          <NotificationIcon/>
        )
        history.push('/collection')
      });
    } catch (e) {
      notificationContext.displayNotification(
        `Something went wrong`,
        '',
        <NotificationIcon/>
      )
      setIsLoading(false)
      console.log(e)
    }
  }

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock>
        <Text fontWeight={600} fontSize={28} color={'#33CC66'} adaptiveFontSize={24}>Change stake period</Text>
        <NftInfoWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={16} color={'rgba(24, 24, 51)'} adaptiveFontSize={14}>Staking Level</Text>
            <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51)'} adaptiveFontSize={18}>{getStakePercent()}</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={16} color={'rgba(24, 24, 51)'} adaptiveFontSize={14}>End of staking</Text>
            <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51)'} adaptiveFontSize={18}>{endOfStaking}</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={16} color={'rgba(24, 24, 51)'} adaptiveFontSize={14}>Reward</Text>
            {reward / 10 ** 18 > 0 ?
              <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51)'} adaptiveFontSize={18}>{(reward / 10 ** 18).toFixed(0)}</Text>
              :
              <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51)'} adaptiveFontSize={18}>Rewards not calculated</Text>
            }
          </TextWrapper>
        </NftInfoWrapper>
        <ButtonV2 outlined isValid onClick={unlock}>
          {
            isLoading ?
              <Spinner color={'#04C35C'} size={25}/>
              :
              <span>Unlock</span>
          }
        </ButtonV2>
      </ChooseTierBlock>
    </JustifyCenterColumn>
  );
};

export default ChangeStakePeriodModal;