import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default (props) => {
  const {fill, width, height} = props;
  return (
    <svg width={width} height={height} viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M76.2194 38.13C55.1694 38.13 38.1097 21.0612 38.1097 0C38.1097 21.0612 21.05 38.13 0 38.13C21.05 38.13 38.1097 55.1988 38.1097 76.26C38.1097 55.1988 55.1694 38.13 76.2194 38.13Z" fill={fill} />
    </svg>
  );
};
