import React from "react";
import {ArtworkImage} from "../NFTTile/styled";

type CurrentNFTGallerySkeletonPropType = {
  elementsCount: number
}

const CurrentNFTGallerySkeletonDefaultProps = {}

const CurrentNFTGallerySkeleton = (props: CurrentNFTGallerySkeletonPropType) => {
  const {elementsCount} = props

  return (
    <>
      {Array.from(Array(elementsCount).keys()).map((mock, index) => (
        <ArtworkImage
          minWidth={200}
          minHeights={200}
          maxWidth={200}
          maxHeightMobile={140}
          maxWidthMobile={140}
          maxWidthAfter1000={105}
          maxHeightAfter1000={105}
          maxHeightAfter900={90}
          key={index}
          className='skeleton'
        />
      ))}
    </>
  )
};

CurrentNFTGallerySkeleton.defaultProps = CurrentNFTGallerySkeletonDefaultProps

export default CurrentNFTGallerySkeleton