export default {
  mintHere: {
    en: 'mint your revenue sharing NFT here',
    ja: ''
  },
  mint: {
    en: 'Mint',
    ja: ''
  }
}
