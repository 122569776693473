export default {
  collection:{
    en: 'Collection',
    ja: 'コレクション'
  },
  subheaderTitle: {
    en: 'RWA Store',
    ja: 'RWA ストア'
  },
}
