import React, { useContext, useEffect, useState } from "react";
import { RowCentered, JustifyCenterColumn } from "Standard/styles/GlobalStyledComponents";
import styled, { css } from "styled-components";
import Star from "components/MMPROLabs/images/Star";
import Cube from "components/MMPROLabs/images/Cube";
import LabsButton from "../LabsButton";
import Text from "../../Text";
import { Input, Radio, RadioChangeEvent, Space } from "antd";
import "./index.css";
import { useNFTLabsContract } from "hooks/useLabsContract";
import fromExponential from "from-exponential";
import { useWeb3React } from "@web3-react/core";
import {useMMProContract, useWeb3} from "Standard/hooks/useCommonContracts";
import { getMMProLabsContract } from "utils/getAddress";
import { useBalanceOfMMPRO } from "hooks/useBalance";
import Spinner from "Standard/components/Spinner";
import { wei2eth } from "Standard/utils/common";
import NotificationContext from "Standard/utils/NotificationContext";
import { localized } from "../../../Standard/utils/localized";
import texts from "./localization";
import NotificationIcon from "../../../Standard/icons/notificationIcon";
import { useParams } from "react-router-dom";
import LocaleContext from "../../../Standard/LocaleContext";

type LabsModalPropType = {
  isActiveInCollection: boolean,
  lockId?: number
}

const LabsModalPropTypeDefaultProps = {
  isActiveInCollection: false
};

const MintBlock = styled(RowCentered)<{ isActiveInCollection: boolean }>`
  position: relative;
  background: linear-gradient(30deg, rgb(67, 173, 101) 0%, rgb(75, 204, 125) 100%);
  width: 440px;
  height: 130px;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
  z-index: 100;

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
  }
`;

const ChooseTierBlock = styled(RowCentered)<{ isActiveInCollection: boolean }>`
  border-radius: 30px 30px 0 0;
  background: #fff;
  width: 440px;
  height: max-content;
  padding: 45px 0;
  z-index: 100;

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
  }
`;

const StarWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10%;
  width: 60px;
  height: 60px;

  @media screen and (max-width: 1200px) {
    width: 30px;
    height: 30px;
    left: 5%;
  }
`;

const CubeWrapper = styled.div`
  position: absolute;
  bottom: -40px;
  right: 5%;
  width: 80px;
  height: 90px;
`;

const ButtonWrapper = styled.div`
  width: 220px;
`;

const LabsModal = (props: LabsModalPropType) => {
  const { isActiveInCollection, lockId } = props;
  const {locale} = useContext(LocaleContext)
  const web3 = useWeb3()
  const { account } = useWeb3React();
  const [chosenTier, setChosenTier] = useState(0);
  const [allowance, setAllowance] = useState<string>("");
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [changeStakeLoading, setChangeStakePeriodLoading] = useState(false);
  const [nftPrice, setNftPrice] = useState<number>(0);

  const notification = useContext(NotificationContext);

  const params: { id: string } = useParams();

  const { balance, balanceLoading, updateBalance } = useBalanceOfMMPRO();

  const handleTierChange = (e: RadioChangeEvent) => {
    setChosenTier(e.target.value);
  };

  const NFTLabsContract = useNFTLabsContract();
  const MMProContract = useMMProContract();

  const getNFTPriceFromContract = async () => {
    const price = await NFTLabsContract.methods.NFTPrice().call();
    setNftPrice(+price / 10 ** 18);
  };
  const approve = async () => {

    if (isApproveLoading) {
      return;
    }
    const ALLOWANCE = web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1));
    const gasPrice = await web3.eth.getGasPrice();
    setIsApproveLoading(true);
    try {
      await MMProContract.methods.approve(getMMProLabsContract(), ALLOWANCE)
        .send({ from: account, gasPrice: gasPrice })
        .once("receipt", () => {
          updateAllowance();
          setIsApproveLoading(false);
        });

    } catch (e) {
      setIsApproveLoading(false);
    }
  };
  const getAllowance = async (): Promise<string> => {
    return await MMProContract
      .methods
      .allowance(account, getMMProLabsContract())
      .call();
  };
  const updateAllowance = async () => {
    const newAllowance = await getAllowance();
    setAllowance(newAllowance);
  };
  const mint = async () => {
    setIsMintLoading(true);
    try {
      await NFTLabsContract
        .methods
        .mint(chosenTier)
        .send({ from: account }).once("receipt", () => {
          notification.displayNotification(
            `You successfully staked!`,
            "",
            <NotificationIcon />
          );
          setIsMintLoading(false);
        });
    } catch (e) {
      notification.displayNotification(
        `Something went wrong`,
        "",
        <NotificationIcon />
      );
      setIsMintLoading(false);
    }
  };
  const changeStakePeriod = async () => {
    setChangeStakePeriodLoading(true);
    try {
      await NFTLabsContract.methods.changeLockId(+params.id, +chosenTier)
        .send({ from: account }).once("receipt", () => {
          notification.displayNotification(
            `You successfully change period!`,
            "",
            <NotificationIcon />
          );
          setChangeStakePeriodLoading(false);
        });

    } catch (e) {
      notification.displayNotification(
        `Something went wrong`,
        "",
        <NotificationIcon />
      );
      setChangeStakePeriodLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    updateAllowance();
    updateBalance();
    getNFTPriceFromContract();
  }, [account]);

  const isApprovalRequired = parseInt(allowance) <= +nftPrice?.toFixed(0);

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock isActiveInCollection={isActiveInCollection}>
        <JustifyCenterColumn>
          <Text fontWeight={700} fontSize={28} color={"#33CC66"} adaptiveFontSize={24}>
            {isActiveInCollection ?
              "Select staking"
              :
              "Mint NFT"
            }
          </Text>
          <div className={"mb-7"} />
          {
            !isActiveInCollection &&
            <>
              <Text fontWeight={500} fontSize={16}>{localized(texts.amount, locale)}</Text>
              <div className={"mb-2"} />
              <Text fontWeight={700} fontSize={24} color={"#33CC66"}>{nftPrice?.toFixed(0)}
                <Text fontWeight={600} fontSize={16} color={"#33CC66"}>MMPro</Text>
              </Text>
              <div className={"mb-7"} />
              <Text fontWeight={500} fontSize={16}>{localized(texts.lockUpPeriod, locale)}</Text>
              <div className={"mb-5"} />
            </>
          }
          <Radio.Group onChange={handleTierChange} value={chosenTier}>
            <Space direction="vertical">
              <Radio value={0} disabled={lockId === 0 || lockId === 1 || lockId === 2}>
                <Text fontWeight={700} fontSize={16} color={"#33CC66"}>{localized(texts.month1, locale)}</Text>
              </Radio>
              <Radio value={1} disabled={lockId === 1 || lockId === 2}>
                <Text fontWeight={700} fontSize={16} color={"#33CC66"}>{localized(texts.month6, locale)}</Text>
              </Radio>
              <Radio value={2} disabled={lockId === 2}>
                <Text fontWeight={700} fontSize={16} color={"#33CC66"}>{localized(texts.month12, locale)}</Text>
              </Radio>
            </Space>
          </Radio.Group>
        </JustifyCenterColumn>
      </ChooseTierBlock>
      <MintBlock isActiveInCollection={isActiveInCollection}>
        <StarWrapper>
          <Star fill={"#fff"} width={60} height={60} />
        </StarWrapper>
        <CubeWrapper>
          <Cube fill={"#fff"} width={80} height={90} />
        </CubeWrapper>
        {
          account ?
            <>
              {
                isActiveInCollection ?
                  <ButtonWrapper>
                    <LabsButton
                      isValid={!(lockId === +chosenTier)}
                      onClick={changeStakePeriod}
                    >
                      {
                        changeStakeLoading ?
                          <Spinner color={"#33CC66"} size={25} />
                          :
                          <Text
                            fontWeight={600}
                            fontSize={16}
                            color={lockId === +chosenTier ? "rgba(25, 25, 50, .3)" : "#33CC66"}
                          >
                            {localized(texts.changePeriod, locale)}
                          </Text>
                      }
                    </LabsButton>
                  </ButtonWrapper>
                :
                <>
                  {
                    +nftPrice.toFixed(0) > +(+balance / 10 ** 18).toFixed(0) ?
                      <Text fontWeight={600} fontSize={16} color={"#fff"}>{localized(texts.dontHave, locale)}</Text>
                      :
                      <ButtonWrapper>
                        <LabsButton
                          isValid
                          onClick={isApprovalRequired ? approve : mint}
                        >
                          {(isApproveLoading || isMintLoading) ?
                            <Spinner color={"#33CC66"} size={25} />
                            :
                            <Text fontWeight={600} fontSize={16}
                                  color={"#33CC66"}>{isApprovalRequired ? localized(texts.Approve, locale) : localized(texts.Mint, locale)}</Text>
                          }
                        </LabsButton>
                      </ButtonWrapper>
                  }
                </>
              }
            </>
            :
            <Text fontWeight={600} fontSize={16} color={"#fff"}>{localized(texts.connectWallet, locale)}</Text>
        }
      </MintBlock>
    </JustifyCenterColumn>
  );
};

LabsModal.defaultProps = LabsModalPropTypeDefaultProps;

export default LabsModal;