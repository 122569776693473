import React, { useEffect, useState } from "react";
import "./index.css";
import { CurrentProject } from "../../types";
import NFTTileSimple from "../../components/NFTTileSimple";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import ReturnBackPanel from "../../components/ReturnBackPanel";
import CurrentProjectWrapper from "../../components/CurrentProjectWrapper";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import { instance } from "../../api";
import NoPageError from "../../Standard/components/404";

const ProjectCollectionPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 4;
`;

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1500px;
  z-index: 1;
  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const ProjectCollection = () => {
  const params: { id: string } = useParams();
  const [currentProject, setCurrentProject] = useState<CurrentProject | undefined>(undefined);
  const [tokenLoading, setTokenLoading] = useState<boolean>(true)
  const getTokens = async () => {
    setTokenLoading(true)
    try {
      const response = await instance.get(`/nft?projectUuid=${params.id}`);
      setCurrentProject(response.data.data);
    } catch (e) {
      console.log(e);
    } finally {
      setTokenLoading(false)
    }
  };

  useEffect(() => {
    getTokens();
  }, []);

  if (!currentProject && !tokenLoading) {
    return <NoPageError />
  }

  return (
    <>
      <ProjectCollectionPage>
        <NFTMarketplaceSubHeader
          subtitle={currentProject?.project.name}
        />
        <Container>
          <ReturnBackPanel href={`/`} />
          <CurrentProjectWrapper project={currentProject?.project}>
            {
              currentProject && currentProject.tokensInfo ?
                <>
                  {currentProject.tokensInfo.map(token => {
                    return (
                      <NFTTileSimple
                        key={token.nftId}
                        projectId={currentProject.project.uuid}
                        token={token}
                      />
                    )
                  })}
                </>
                :
                <>
                  <NFTTileSimple token={undefined} projectId={''}/>
                  <NFTTileSimple token={undefined} projectId={''}/>
                  <NFTTileSimple token={undefined} projectId={''}/>
                  <NFTTileSimple token={undefined} projectId={''}/>
                  <NFTTileSimple token={undefined} projectId={''}/>
                </>
            }
          </CurrentProjectWrapper>
        </Container>
        <GradientCircles />
      </ProjectCollectionPage>
      <GradientCircles />
    </>
  );
};

export default ProjectCollection;