import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import texts from "./localization";
import LocaleContext from "Standard/LocaleContext";
import { localized } from "Standard/utils/localized";
import { useNFTLabsContract } from "hooks/useLabsContract";
// import { NftForSale } from "types";
import styled from "styled-components";
import { useWeb3React } from "@web3-react/core";
import NftForSaleCard from "components/NftForSaleCard";
import NFTMarketplaceSubHeader from "components/SubHeader/variants/NftMarketplace";
import ReturnBackPanel from "components/ReturnBackPanel";
import Spinner from "Standard/components/Spinner";
import Text from "components/Text";
import { RowCentered } from "Standard/styles/GlobalStyledComponents";
import Notification from "../../components/Notification";
import { getMMProLabsContract } from "../../utils/getAddress";
import { useMMProContract } from "../../Standard/hooks/useCommonContracts";
import fromExponential from "from-exponential";
import {useIvendContract} from "../../hooks/useIvendContract";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import axios from "axios";
import {instance} from "../../api";

type NftForSalePropType = {}

const NftForSaleDefaultProps = {};

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 80px;
  padding-left: 80px;
  gap: 30px;
`;

const SpinnerWrapper = styled(RowCentered)`
  width: 100%;
`;

interface NftForSale {
  tokenId: string,
  basePrice: string,
  isUserNft: boolean
}

interface NftsForSale {
  sellableNfts: NftForSale[]
}

const NftsForSale = (props: NftForSalePropType) => {
  const { locale } = useContext(LocaleContext);

  const { account } = useWeb3React();
  const ivendContract = useIvendContract()

  const [allNfts, setAllNfts] = useState<NftForSale[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [markup, setMarkup] = useState(0)

  const MMPROLabsContract = useNFTLabsContract();

  const getResaleFeeAndFeeDecimals = async () => {
    const resaleFee = await MMPROLabsContract.methods.resaleFee().call()
    const feeDecimals = await MMPROLabsContract.methods.FEE_DECIMALS().call()

    if (resaleFee !== undefined && feeDecimals !== undefined) {
      setMarkup(+resaleFee / +feeDecimals)
    }
  }

  const getNfts = async () => {
    if (!account) return
    setIsLoading(true)
    try {
      const response = await instance.get(`/labs/nft/sellable?wallet=${account}`)
      setAllNfts(response.data.data.sellableNfts)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getResaleFeeAndFeeDecimals()
  }, [])

  useEffect(() => {
    getNfts()
  }, [account]);

  return (
    <FlexWrapper>
      <NFTMarketplaceSubHeader title={"NFT Labs"} subtitle={"Marketplace"} />
      <ReturnBackPanel />
      {
        !account &&
        <SpinnerWrapper>
          <div style={{ marginTop: 40 }} />
          <Notification body={localized(texts.connectWalletPrompt, locale)} />
        </SpinnerWrapper>
      }
      {
        account &&
        <>
          {
            !isLoading ?
              <>
                {
                  allNfts.length ?
                    <>
                      {allNfts.map(nft => {
                        if(!nft){
                          return null
                        }
                        return (
                          <NftForSaleCard nft={nft} key={nft.tokenId} markup={markup} />
                        );
                      })}
                    </>
                    :
                    <SpinnerWrapper>
                      <Text fontSize={24} fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
                    </SpinnerWrapper>
                }
              </>
              :
              <SpinnerWrapper>
                <Spinner color={"#04C35C"} size={25} />
              </SpinnerWrapper>
          }
        </>
      }
    </FlexWrapper>
  );
};

NftsForSale.defaultProps = NftForSaleDefaultProps;

export default NftsForSale;

