export default {
  dontHaveNfts: {
    en: "There is no NFTs in marketplace",
    ja: "あなたのコレクションにはまだ NFT がありません。"
  },
  connectWalletPrompt: {
    en: 'Please connect wallet to see the marketplace',
    ja: 'ウォレットを接続してマーケットプレイスを表示してください'
  },
}
