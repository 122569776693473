import React, {useContext, useEffect, useState} from 'react';
import texts from './localization'
import {localized} from "Standard/utils/localized";
import Text from "components/Text";
import {SpaceBetweenRow} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import LabsButton from "../LabsButton";
import LocaleContext from "Standard/LocaleContext";
import Cover from '../images/NFTMMPROLABSCover.svg';
import Modal from 'Standard/components/Modal'
import LabsModal from "../LabsModal";
import {instance} from "../../../api";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  position: relative;
  z-index: 2;
  margin-bottom: 100px;

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 5%;
    margin-bottom: 40px;
  }
`

const ContentWrapper = styled(SpaceBetweenRow)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 2;
  max-width: 1088px;

  @media screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 5%;
  }`

const GreenBackground = styled.div`
  position: relative;
  padding-top: 10px;
  border-radius: 30px;
  width: 90%;
  max-width: 1600px;
  height: 450px;
  background: linear-gradient(174deg, rgb(67, 221, 90) 0%, rgb(63, 155, 91) 100%);

  @media screen and (max-width: 1200px) {
    height: 650px;
  }
`

const TextAndImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  transform: translateY(15px);
  display: flex;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`

const SmallScreenImageWrapper = styled.div`
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
    margin-bottom: 20px;
    width: 210px;
  }
`

const TextWrapper = styled.div`
  z-index: 1;

  @media screen and (max-width: 1200px) {
    text-align: center;
    max-width: 320px;
  }
`

const DescriptionWrapper = styled.div`
  max-width: 390px;
`

const ButtonWrapper = styled.div`
  width: 340px;

  @media screen and (max-width: 1300px) {
    width: 240px;
  }
`

const WelcomeBlock = () => {
	const {locale} = useContext(LocaleContext)
	const [isModalActive, setIsModalActive] = useState(false)
	const [totalSupply, setTotalSupply] = useState<undefined | number>(undefined)

	const getTotalSupplyLabs = async () => {
		try {
			const response = await instance.get('/labs/nft/supply')
			setTotalSupply(response.data.data.totalSupply)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getTotalSupplyLabs()
	}, [])

	return (
		<Container>
			<GreenBackground>
				<img src={Cover} alt=""/>
				<ContentWrapper>
					<TextWrapper>
						<TextAndImageWrapper>
							<Text
								fontWeight={600}
								fontSize={40}
								adaptiveFontSize={16}
								color={'#fff'}
							>
								{localized(texts.mmproLabs, locale)}
							</Text>
							<Text
								fontWeight={700}
								fontSize={48}
								adaptiveFontSize={24}
								color={'#fff'}
							>
								{localized(texts.club, locale)}
							</Text>
							<DescriptionWrapper>
								<Text
									fontWeight={500}
									fontSize={20}
									color={'#fff'}
									adaptiveFontSize={12}
								>
									{localized(texts.description, locale)}
								</Text>
							</DescriptionWrapper>
							<div className='mb-7'/>
							<SmallScreenImageWrapper>
								<img src={'/images/LabsCover.png'}/>
							</SmallScreenImageWrapper>
							{totalSupply && totalSupply < 100 ?
								<ButtonWrapper>
									<LabsButton onClick={() => setIsModalActive(true)} isValid>
										<Text
											fontWeight={600}
											fontSize={16}
											color={'#33CC66'}
										>
											{localized(texts.mintNft, locale)}
										</Text>
									</LabsButton>
								</ButtonWrapper>
								:
								<Text
									fontWeight={700}
									fontSize={32}
									color={'#fff'}
									adaptiveFontSize={12}
								>
									SOLD OUT
								</Text>
							}
							<div className='mb-7'/>
							{totalSupply &&
                  <Text
                      fontWeight={500}
                      fontSize={20}
                      color={'#fff'}
                      adaptiveFontSize={12}
                  >
                      Now sold - {totalSupply} out of 100 NFT Labs
                  </Text>
							}
						</TextAndImageWrapper>
					</TextWrapper>
					<ImageWrapper>
						<img src={'/images/LabsCover.png'}/>
					</ImageWrapper>
				</ContentWrapper>
			</GreenBackground>
			{
				isModalActive &&
          <Modal onClose={setIsModalActive} title={"test"}>
              <LabsModal isActiveInCollection={false}/>
          </Modal>
			}
		</Container>
	);
};

export default WelcomeBlock;