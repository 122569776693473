export default {
  howMuchCanYouEarn: {
    en: 'HOW MUCH CAN YOU EARN ?',
    ja: ''
  },
  earnDetailText: {
    en: '5% -10% from MMPro RWA Store Revenue is pooled for distribution between NFT holders.',
    ja: ''
  },
  earnDisclosure: {
    en: '(depending on the allocation and price of the NFT)',
    ja: ''
  },
  howMuchCanIEarn: {
    en: 'How much will I earn?',
    ja: ''
  },
  Rewards: {
    en: 'Rewards are paid in MMPro tokens',
    ja: ''
  },
}
