import React, {useContext, useState} from 'react';
import {RowCentered, JustifyCenterColumn} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import './index.css';
import Text from 'components/Text'
import SimpleLabelContainer from "Standard/components/SimpleLabelContainer";
import SimpleInput from "Standard/components/SimpleInput";
import useValidatedState, {validationFuncs} from "Standard/hooks/useValidatedState";
import ButtonV2 from "Standard/components/ButtonV2";
import {useNFTLabsContract} from "../../../hooks/useLabsContract";
import NotificationContext from "../../../Standard/utils/NotificationContext";
import NotificationIcon from "../../../Standard/icons/notificationIcon";
import {useHistory, useParams} from "react-router-dom";
import {useWeb3React} from "@web3-react/core";
import Spinner from "../../../Standard/components/Spinner";
import BigNumber from "bignumber.js";
import {useIvendContract} from "../../../hooks/useIvendContract";

const ChooseTierBlock = styled(RowCentered)`
  border-radius: 30px;
  background: #fff;
  width: 540px;
  height: max-content;
  padding: 45px;
  gap: 15px;

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
    padding: 30px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px
`

const SaleLabsNftModal = () => {

  const {account} = useWeb3React()
  const isIvend = window.location.search.includes("ivend");


  const [[price, setPrice], priceValid] = useValidatedState<string>("", validationFuncs.hasValue)
  const [isLoading, setIsLoading] = useState(false)

  const params: {id: string} = useParams()

  const MMPROLabsContract = useNFTLabsContract()
  const ivendContract = useIvendContract()

  const notificationContext = useContext(NotificationContext)
  const history = useHistory()

  const sellNft = async () => {
    setIsLoading(true)
    const priceForSale = (new BigNumber(10).pow(18).multipliedBy(+price)).toString()
    try {
      if(isIvend){
        await ivendContract.methods.putUpForSales(+params.id, priceForSale).send({from: account}).once('receipt', () => {
          notificationContext.displayNotification(
            `NFT was put up for sale`,
            '',
            <NotificationIcon/>
          )
          history.push('/collection')
        });
      }else{
        await MMPROLabsContract.methods.PutUpForSales(+params.id, priceForSale).send({from: account}).once('receipt', () => {
          notificationContext.displayNotification(
            `NFT was put up for sale`,
            '',
            <NotificationIcon/>
          )
          history.push('/collection')
        });
      }

    } catch (e) {
      notificationContext.displayNotification(
        `Something went wrong`,
        '',
        <NotificationIcon/>
      )
      setIsLoading(false)
      console.log(e)
    }
  }

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock>
        <Text fontWeight={600} fontSize={28} color={'#33CC66'}>Sell NFT</Text>
        <SimpleLabelContainer
          label={'Price'}
        >
          <SimpleInput
            onlyEmmitOnBlur
            onChangeRaw={setPrice}
            isValid={priceValid}
            required
            errorTooltipText={'Price must be greater than 0'}
            autoComplete="price"
            inputProps={{
              className: `w-full`,
              placeholder: '0',
              type: 'number',
              value: price
            }}
          />
        </SimpleLabelContainer>
        <TextWrapper>
          <Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51)'}>MMPRO Commission:</Text>
          <Text fontWeight={600} fontSize={20} color={'rgba(24, 24, 51)'}>5%</Text>
          <Text fontWeight={600} fontSize={20} color={'#33CC66'}>{(+price * 0.05).toFixed(0)} MMPRO</Text>
        </TextWrapper>
        <TextWrapper>
          <Text fontWeight={500} fontSize={16} color={'rgba(24, 24, 51)'}>Final price including commission:</Text>
          <Text fontWeight={600} fontSize={20} color={'#33CC66'}>{(+price + (+price * 0.05)).toFixed(0)} MMPRO</Text>
        </TextWrapper>
        <ButtonV2 isValid={priceValid} onClick={sellNft}>
          {
            isLoading ?
              <Spinner color={'#fff'} size={25}/>
              :
              <span>Sell</span>
          }
        </ButtonV2>
      </ChooseTierBlock>
    </JustifyCenterColumn>
  );
};

export default SaleLabsNftModal;