import React, { useContext, useEffect, useState } from "react";
import texts from "./localization";
import { localized } from "../../Standard/utils/localized";
import "./index.css";
import { ArtworkImage } from "../NFTTile/styled";
import styled from "styled-components";
import { CurrentToken, NFT, ProjectToken, Token } from "../../types";
import { useHistory, useParams } from "react-router-dom";
import { AllProjects } from "../../mocks/AllProjects";
import Text from "../Text";
import LocaleContext from "../../Standard/LocaleContext";
import CurrentNFTGallerySkeleton from "../CurrentNFTGallerySkeleton";
import { API_URL } from "../../api";

type CurrentNftGalleryPropType = {
  otherTokens: ProjectToken[] | undefined
  currentToken: ProjectToken | undefined
  projectId: string | undefined
}

const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  max-width: 416px;
  margin-right: 50px;

  @media screen and (max-width: 1240px) {
    max-width: 300px;
  }

  @media screen and (max-width: 1240px) {
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
`;

const OtherTiersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const ArtworkImageWrapper = styled.div`
  cursor: pointer;
`;

const CurrentNFTGallery = (props: CurrentNftGalleryPropType) => {

  const params: { id: string } = useParams();
  const { currentToken, otherTokens, projectId } = props;
  const { locale } = useContext(LocaleContext);

  const history = useHistory();

  return (
    <>
      <ColumnFlex>
        <ArtworkImage
          className={`${!currentToken?.imagePath && "skeleton"}`}
          minWidth={416}
          minHeights={416}
          maxWidth={416}
          src={`${API_URL}/${currentToken?.imagePath}`}
        />
        <Text fontWeight={600} fontSize={20} color={"rgba(24, 24, 51, .5)"}>{localized(texts.otherTiers, locale)}</Text>
        <OtherTiersWrapper>
          {
            otherTokens && otherTokens.length && projectId ? otherTokens.map((token, index) => (
                <ArtworkImageWrapper
                  key={token.nftId}
                  onClick={() => history.push(`/projects/${projectId}/${token.nftId}`)}
                >
                  <ArtworkImage
                    minWidth={200}
                    minHeights={200}
                    maxWidth={200}
                    maxHeightMobile={140}
                    maxWidthMobile={140}
                    maxWidthAfter1000={105}
                    maxHeightAfter1000={105}
                    maxHeightAfter900={90}
                    maxWidthAfter900={90}
                    key={index + (index >= +params.id.slice(params.id.length - 1) ? 1 : 0)}
                    src={`${API_URL}/${token.imagePath}`}
                  />
                </ArtworkImageWrapper>
              ))
              :
              <CurrentNFTGallerySkeleton elementsCount={4} />
          }
        </OtherTiersWrapper>
      </ColumnFlex>
    </>
  );
};

export default CurrentNFTGallery;