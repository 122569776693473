import React, {useCallback, useContext, useEffect, useState} from "react";
import LocaleContext from "Standard/LocaleContext";
import "./index.css";
import {useWeb3React} from "@web3-react/core";
import styled from "styled-components";
import Notification from "components/Notification";
import {ArtworkImage} from "components/NFTTile/styled";
import NftProjectContainer from "components/NftProjectContainer";
import {CollectionStoreToken, NFT, ProjectsDict, Token} from "types";
import {AllProjects} from "mocks/AllProjects";
import NFTTransferForm from "components/NFTTransferForm";
import WalletConnectorBubbleContext from "Standard/WalletConnectorBubbleContext";
import Spinner from "Standard/components/Spinner";
import {localized} from "Standard/utils/localized";
import texts from "./localization";
import ReturnBackPanel from "components/ReturnBackPanel";
import Text from "components/Text";
import NFTMarketplaceSubHeader from "components/SubHeader/variants/NftMarketplace";
import GradientCircles from "Standard/decorations/GradientCircles";
import {useNFTLabsContract} from "hooks/useLabsContract";
import {useHistory} from "react-router-dom";
import IosStyleSegmentedControll from "Standard/components/IosStyleSegmentedControll";
import {JustifyStartColumn, RowCentered} from "Standard/styles/GlobalStyledComponents";
import {API_URL, instance} from "../../api";
import {useIvendContract} from "../../hooks/useIvendContract";

const CollectionWrapper = styled.div`
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 4;
  @media screen and (max-width: 900px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

const NFTWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
`;

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const StyledNftLabsImg = styled.img`
  width: 350px;
  height: 470px;
  margin-bottom: 10px;
`;

const ColumnFlex = styled(JustifyStartColumn)`
  margin-top: 30px;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const NFTItemWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  background: #fff;
  padding: 16px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s;

  &:hover {
    transform: scale(1.015)
  }
`;

const SpinnerWrapper = styled(RowCentered)`
  width: 100%;
`;

const Collection = (props: { isOpen?: boolean }) => {
	const {locale} = useContext(LocaleContext);
	const {setBubbleValue} = useContext(WalletConnectorBubbleContext);
	const {isOpen} = props;
	const {account} = useWeb3React();

	const [tokensIdFromSale, setTokensIdFromSale] = useState<any[]>([]);
	const [ivendTokensForSale, setIvendTokensForSale] = useState<any[]>([]);

	const [isUserNotHasSaleNfts, setIsUserNotHasSaleNfts] = useState(false);
	const [labsNfts, setLabsNfts] = useState<undefined | any[]>(undefined);
	const [tokensOnSale, setTokensOnSale] = useState<undefined | any[]>(undefined);
	const [ivendTokensOnSale, setIvendTokensOnSale] = useState<undefined | any[]>(undefined);

	const [activeTab, setActiveTab] = useState(0);
	const [isLabsLoading, setIsLabsLoading] = useState(false);
	const [isStoreLoading, setIsStoreLoading] = useState(false)
	const [markup, setMarkup] = useState(0)

	const [ivendNfts, setIvendNfts] = useState<undefined | any[]>(undefined);
	const [isIvendLoading, setIsIvendLoading] = useState(false);


	const [userStoreTokens, setUserStoreTokens] = useState<CollectionStoreToken | undefined>(undefined)

	const MMPROLabsContract = useNFTLabsContract();
	const ivendContract = useIvendContract()


	const tabsArray = ["LABS", "STORE", "ON SALE"];

	const history = useHistory();

	useEffect(() => {
		if (isOpen) {
			setBubbleValue("");
		}
	}, [isOpen]);

	const getUserStoreNFTs = async () => {
		setIsStoreLoading(true)
		try {
			const response = await instance.get(`/nft/collection?wallet=${account}`)
			setUserStoreTokens(response.data.data.userCollection)
		} catch (e) {
			console.log(e);
		} finally {
			setIsStoreLoading(false)
		}
	}
	const getResaleFeeAndFeeDecimals = async () => {
		const resaleFee = await MMPROLabsContract.methods.resaleFee().call()
		const feeDecimals = await MMPROLabsContract.methods.FEE_DECIMALS().call()

		if (resaleFee !== undefined && feeDecimals !== undefined) {
			setMarkup(+resaleFee / +feeDecimals)
		}
	}

	const getTokensFromSale = useCallback(async () => {
		let newNfts: number[] = [];
		for (let i = 0; i < 99999; i++) {
			let nft;
			try {
				nft = {...(await MMPROLabsContract.methods.nftsForSale(i).call())};
			} catch (e) {

				if (newNfts.length) {
					console.log(e);
				} else {
					setIsUserNotHasSaleNfts(true);
				}
				break;
			}
			newNfts.push(nft);
		}
		setTokensIdFromSale(newNfts);
	}, []);

	// const getIvendTokensForSale = useCallback(async () => {
	// 	let newNfts: any[] = [];
	// 	for (let i = 0; i < 40; i++) {
	// 		let nft;
	// 		try {
	// 			nft = {price: (await ivendContract.methods.NFTForSale(i).call()), id: i};
	// 			const nftTier = await ivendContract.methods.NFTtoTier(nft.id).call();
	// 			const tierInfo = await ivendContract.methods.nfts(nftTier).call();
	//
	// 			nft = {
	// 				...nft,
	// 				...tierInfo
	// 			}
	// 		} catch (e) {
	// 			if (newNfts.length) {
	// 				console.log(e);
	// 			} else {
	// 				setIsUserNotHasSaleNfts(true);
	// 			}
	// 			break;
	// 		}
	//
	// 		if (nft.price > 0) {
	// 			newNfts.push(nft);
	// 		}
	// 	}
	// 	console.log(newNfts);
	//
	// 	setIvendTokensForSale(newNfts);
	// }, []);
	const getLabsNfts = async () => {
		setIsLabsLoading(true);
		if (account && (isUserNotHasSaleNfts || tokensIdFromSale.length)) {
			const userNfts = [];
			const userNftsOnSale: any[] = [];
			for (let i = 0; i <= 99999; i++) {
				let newNft: any;
				try {
					const res = await MMPROLabsContract.methods.tokenOfOwnerByIndex(account, +i).call();
					if (res) {
						const nftInfo = await MMPROLabsContract.methods.mapTokenLockInfo(res).call();

						newNft = {
							nftId: +res,
							...nftInfo
						};

						userNfts.push(newNft);

						tokensIdFromSale.filter(token => {
							if (+token.tokenId === +newNft.nftId) {
								userNftsOnSale.push({...newNft, basePrice: token.basePrice})
							}
						})

					}
				} catch (e) {
					console.log(e);
					break;
				} finally {
					setTimeout(() => {
						setIsLabsLoading(false);
					}, 3500)
				}
			}
			setLabsNfts(userNfts);
			setTokensOnSale(userNftsOnSale);
		}
	};

	const getIvendNfts = async () => {
		setIsIvendLoading(true);
		if (account && (ivendTokensForSale != undefined && ivendTokensForSale.length)) {
			const userNfts = [];
			const userNftsOnSale: any[] = [];
			for (let i = 0; i <= 99999; i++) {
				let newNft: any;
				try {
					const res = await ivendContract.methods.tokenOfOwnerByIndex(account, +i).call();
					if (res) {
						const tier = await ivendContract.methods.NFTtoTier(res).call();
						const tierInfo = await ivendContract.methods.nfts(tier).call();


						newNft = {
							nftId: +res,
							tier,
							...tierInfo
						};

						userNfts.push(newNft);

						ivendTokensForSale.forEach(token => {
							if (+token.id === +newNft.nftId) {
								userNftsOnSale.push({...newNft, basePrice: token.price})
							}
						})

					}
				} catch (e) {
					console.log(e);
					break;
				} finally {
					setTimeout(() => {
						setIsIvendLoading(false);
					}, 3500)
				}
			}
			setIvendTokensOnSale(userNftsOnSale);
			setIvendNfts(userNfts);
		}
	};

	useEffect(() => {
		getTokensFromSale();
		// getIvendTokensForSale()
		getResaleFeeAndFeeDecimals()
	}, []);

	useEffect(() => {
		getLabsNfts();
	}, [account, tokensIdFromSale.length, isUserNotHasSaleNfts]);

	useEffect(() => {
		getIvendNfts()
	}, [account, ivendTokensForSale.length, isUserNotHasSaleNfts]);

	useEffect(() => {
		if (account) {
			getUserStoreNFTs()
		}
	}, [account])

	return (
		<>
			<CollectionWrapper>
				<NFTMarketplaceSubHeader
					title={activeTab === 1 ? "RWA Store" : "NFT Labs"}
					subtitle={localized(texts.myCollection, locale)}
				/>
				<ReturnBackPanel href={`/`}/>
				<TabsWrapper>
					<IosStyleSegmentedControll
						width={400}
						buttons={tabsArray}
						firstSelectedIndex={activeTab}
						onChange={setActiveTab}
					/>
				</TabsWrapper>
				{
					!account &&
          <>
            <div style={{marginTop: 40}}/>
            <Notification body={localized(texts.connectWalletPrompt, locale)}/>
          </>
				}
				{
					account &&
          <div style={{width: "100%"}}>
						{
							activeTab === 0 &&
              <ColumnFlex>
                <RowCentered>
									{
										isLabsLoading ?
											<SpinnerWrapper>
												<Spinner color={"#04C35C"} size={25}/>
											</SpinnerWrapper>
											:
											<>
												{
													labsNfts && labsNfts.length
														?
														<>
															{
																labsNfts.map(tier => {
																	return (
																		<NFTItemWrapper
																			key={tier.nftId}
																			onClick={() => history.push(`/collection/${tier.nftId}`)}
																		>
																			<JustifyStartColumn gap={10}>
																				<StyledNftLabsImg
																					src="/images/LabsCover.png" alt=""/>
																				<Text fontWeight={500} fontSize={18}>LABS NFT</Text>
																				<span>Your reward: {(+tier.cumulatedReward / 10 ** 18).toFixed(1)} MMPRO <br/> NFT Price: {(tier.price / 10 ** 18).toFixed(0)} MMPRO</span>
																			</JustifyStartColumn>
																		</NFTItemWrapper>
																	);
																})}
														</>
														:
														<SpinnerWrapper>
															<Text fontSize={24}
															      fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
														</SpinnerWrapper>
												}
											</>
									}
                </RowCentered>
              </ColumnFlex>
						}
						{
							activeTab === 1 &&
              <ColumnFlex>
                <FlexWrapper>
									{isStoreLoading ?
										<SpinnerWrapper>
											<Spinner color={"#04C35C"} size={25}/>
										</SpinnerWrapper>
										:
										<>
											{
												userStoreTokens && Object.keys(userStoreTokens).length ?
													<>
														{
															Object.keys(userStoreTokens).map(tokenName => (
																<NftProjectContainer key={tokenName} name={tokenName}>
																	{Object.values(userStoreTokens[tokenName]).map(token => (
																		<NFTWrapper key={token.imagePath}>
																			<ArtworkImage maxWidth={350} src={`${API_URL}/${token.imagePath}`}/>
																			<NFTTransferForm
																				totalUserAllocationBUSD={token.allocationBUSD}
																				totalUserAllocationUSDT={token.allocationUSDT}
																			/>
																		</NFTWrapper>
																	))}
																</NftProjectContainer>
															))
														}
													</>
													:
													<SpinnerWrapper>
														<Text fontSize={24}
														      fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
													</SpinnerWrapper>
											}
										</>
									}
                </FlexWrapper>
              </ColumnFlex>
						}
						{
							activeTab === 2 &&
              <ColumnFlex>
                <RowCentered>
									{
										isLabsLoading ?
											<SpinnerWrapper>
												<Spinner color={"#04C35C"} size={25}/>
											</SpinnerWrapper>
											:
											<>
												{(!ivendTokensOnSale || ivendTokensOnSale.length == 0) && tokensOnSale?.length == 0 &&
                          <SpinnerWrapper>
                            <Text fontSize={24}
                                  fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
                          </SpinnerWrapper>
												}
												{
													ivendTokensOnSale && ivendTokensOnSale.length !== 0
													&&
                          <>
														{
															ivendTokensOnSale.map(tier => {
																return (
																	<NFTItemWrapper
																		key={tier.nftId}
																		onClick={() => history.push(`/collection/${tier.nftId}?type=ivend`)}
																	>
																		<JustifyStartColumn gap={10}>
																			<StyledNftLabsImg
																				src={`/images/ivendNfts/Tier${+tier.tier + 1}.png`} alt=""/>
																			<Text fontWeight={500} fontSize={18}>IvendPay
																				NFT</Text>
																			<span>
                                          NFT Price: {(tier.priceInUSDT / 10 ** 18).toFixed(0)} USDT
                                          <br/>
                                          Selling price: {(+tier.basePrice / 10 ** 18 + (+tier.basePrice / 10 ** 18) * markup).toFixed(0)} MMPRO
                                        </span>
																		</JustifyStartColumn>
																	</NFTItemWrapper>
																);
															})}
                          </>
												}
												{
													tokensOnSale && tokensOnSale.length !== 0
													&&
                          <>
														{
															tokensOnSale.map(tier => {
																return (
																	<NFTItemWrapper
																		key={tier.nftId}
																		onClick={() => history.push(`/collection/${tier.nftId}`)}
																	>
																		<JustifyStartColumn gap={10}>
																			<StyledNftLabsImg
																				src="/images/LabsCover.png" alt=""/>
																			<Text fontWeight={500} fontSize={18}>LABS
																				NFT</Text>
																			<span>
                                          Your reward: {(+tier.cumulatedReward / 10 ** 18).toFixed(1)} MMPRO
                                          <br/>
                                          NFT Price: {(tier.price / 10 ** 18).toFixed(0)} MMPRO
                                          <br/>
                                          Selling price: {(+tier.basePrice / 10 ** 18 + (+tier.basePrice / 10 ** 18) * markup).toFixed(0)} MMPRO
                                        </span>
																		</JustifyStartColumn>
																	</NFTItemWrapper>
																);
															})}
                          </>
												}
											</>
									}
                </RowCentered>
              </ColumnFlex>
						}
						{
							activeTab === 3 &&
              <ColumnFlex>
                <RowCentered>
									{
										isIvendLoading ?
											<SpinnerWrapper>
												<Spinner color={"#04C35C"} size={25}/>
											</SpinnerWrapper>
											:
											<>
												{
													ivendNfts && ivendNfts.length
														?
														<>
															{
																ivendNfts.map(tier => {
																	return (
																		<NFTItemWrapper
																			key={tier.nftId}
																			onClick={() => history.push(`/collection/${tier.nftId}?type=ivend`)}
																		>
																			<JustifyStartColumn gap={10}>
																				<StyledNftLabsImg
																					src={`/images/ivendNfts/Tier${+tier.tier + 1}.png`} alt=""/>
																				<Text fontWeight={500} fontSize={18}>IvendPay
																					NFT</Text>
																				<span>
                                          NFT Price: {(tier.priceInUSDT / 10 ** 18).toFixed(0)} USDT
                                          <br/>
                                          Selling price: {tier.basePrice ? (+tier.basePrice / 10 ** 18 + (+tier.basePrice / 10 ** 18) * markup).toFixed(0) : 0} MMPRO
                                        </span>
																			</JustifyStartColumn>
																		</NFTItemWrapper>
																	);
																})}
														</>
														:
														<SpinnerWrapper>
															<Text fontSize={24}
															      fontWeight={600}>{localized(texts.dontHaveNfts, locale)}</Text>
														</SpinnerWrapper>
												}
											</>
									}
                </RowCentered>
              </ColumnFlex>
						}
          </div>
				}
			</CollectionWrapper>
			<GradientCircles/>
		</>
	);
};

export default Collection;
