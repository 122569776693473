import React, {useCallback, useContext, useEffect, useState} from "react";
import LocaleContext from "Standard/LocaleContext";
import styled, {css} from "styled-components";
import {useParams} from "react-router-dom";
import GradientCircles from "Standard/decorations/GradientCircles";
import NFTMarketplaceSubHeader from "components/SubHeader/variants/NftMarketplace";
import ReturnBackPanel from "components/ReturnBackPanel";
import Text from "components/Text";
import {useNFTLabsContract} from "hooks/useLabsContract";
import TokensRecievedIcon from "icons/TokensRecievedIcon";
import WithdrawButtonIcon from "icons/WithdrawButtonIcon";
import SaleIcon from "icons/SaleIcon";
import HandOverIcon from "icons/HandOverIcon";
import {useWeb3React} from "@web3-react/core";
import ButtonV2 from "Standard/components/ButtonV2";
import NotificationContext from "Standard/utils/NotificationContext";
import NotificationIcon from "Standard/icons/notificationIcon";
import SaleLabsNftModal from "components/Modals/SaleLabsNftModal";
import Modal from "Standard/components/Modal";
import TransferLabsNftModal from "components/Modals/TransferLabsNftModal";
import ChangeStakePeriodModal from "components/Modals/ChangeStakePeriodModal";
import {NftForSale} from "../../types";
import Spinner from "../../Standard/components/Spinner";
import LabsModal from "../../components/MMPROLabs/LabsModal";
import ExchangeIcon from "../../icons/ExchangeIcon";
import {useChangeNFTContract} from "../../hooks/useChangeNFTContract";
import {getChangeNFTContract} from "../../utils/getAddress";
import SimpleLabelContainer from "../../Standard/components/SimpleLabelContainer";
import {localized} from "../../Standard/utils/localized";
import texts from "../../components/CurrentNFTBuyForm/localization";
import SimpleInput from "../../Standard/components/SimpleInput";
import useValidatedState, {validationFuncs} from "../../Standard/hooks/useValidatedState";
import axios from "axios";
import {useIvendContract} from "../../hooks/useIvendContract";
import NoPageError from "../../Standard/components/404";
import {useWeb3} from "../../Standard/hooks/useCommonContracts";

type CurrentLabsNftPropType = {}

const CurrentLabsNftDefaultProps = {};

const Container = styled.div`
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1300px) {
    padding-right: 5%;
    padding-left: 5%;
  }
`;

const NFTCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 4;
  width: 860px;
  padding: 37px;
  background: #FFFFFF;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-radius: 16px;
  margin-top: 40px;
  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
    padding: 15px;
  }
`;

const StyledImg = styled.img`
  max-width: 350px;
  width: 100%;
`;


const StyledImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-right: 35px;
  @media screen and (max-width: 1300px) {
    align-items: center;
    margin-right: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TextWrapper = styled.div<{ gap: number }>`
  display: flex;
  align-items: center;
  gap: ${p => p.gap}px;
  @media screen and (max-width: 1200px) {
    gap: 8px;
  }
`;

const ColumnTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  @media screen and (max-width: 1300px) {
    margin-bottom: 20px;
  }
`;

const Line = styled.div`
  width: 303px;
  opacity: 0.2;
  border: 1px solid #181833;
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const WihdrawButtonContentWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const SecondaryButton = styled.div<{ isBordered: boolean, isValid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  ${({isBordered}) => isBordered && css`
    border: 1px solid #33CC66;
    width: 100%;
    height: 50px;
    border-radius: 4px;
  `};
  ${({isValid}) => !isValid && css`
    pointer-events: none;
    color: #191932;
    opacity: 0.4;
  `};
`;

const SecondaryButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 35px;
  @media (max-width: 1200px) {
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
  }
`;

const ExchangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
`

const ExchangeButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 160px;
`

const CurrentLabsNft = (props: CurrentLabsNftPropType) => {
  const { locale } = useContext(LocaleContext);
  const params: { id: string } = useParams();
  const isIvend = window.location.search.includes("ivend");
  const { account } = useWeb3React();
	const web3 = useWeb3()

	const notificationContext = useContext(NotificationContext);

	const [token, setToken] = useState<any>(undefined);
	const [tokensIdFromSale, setTokensIdFromSale] = useState<number[]>([]);
	const [isTokenOnSale, setIsTokenOnSale] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUserCanUnlock, setIsUserCanUnlock] = useState(false);
	const [isTakeFromSaleLoading, setIsTakeFromSaleLoading] = useState<boolean>(false);
	const [isTakeRewardLoading, setIsTakeRewardLoading] = useState<boolean>(false);

	const [saleLabsModalActive, setSaleLabsModalActive] = useState(false);
	const [transferLabsModalActive, setTransferLabsModalActive] = useState(false);
	const [unlockModalActive, setUnlockModalActive] = useState(false);
	const [changePeriodModalActive, setChangePeriodModalActive] = useState(false);

  const MMPROLabsContract = useNFTLabsContract();
  const ivendContract = useIvendContract()
	const ChangeNFTContract = useChangeNFTContract()

	const [terminalSharesAmount, setTerminalSharesAmount] = useState<string>('')
	const [trustSharesAmount, setTrustSharesAmount] = useState<string>('')

	const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false)
	const [isTrustSwapLoading, setIsTrustSwapLoading] = useState<boolean>(false)
	const [isTerminalSwapLoading, setIsTerminalSwapLoading] = useState<boolean>(false)

	const [[email, setEmail], emailValid] = useValidatedState<string>("", validationFuncs.isEmail)

  const getTokenInfo = useCallback(async () => {
    if(isIvend){
      const tier = await ivendContract.methods.NFTtoTier(+params.id).call();
      const tierInfo = await ivendContract.methods.nfts(tier).call();
      setToken({...tierInfo, tier});
      console.log(tierInfo);
      return
    }
    try {
      const tokenInfo = await MMPROLabsContract.methods.mapTokenLockInfo(+params.id).call();
      setToken(tokenInfo);
    } catch (e) {
      console.log(e);
    }
  }, []);

	const getTokensFromSale = useCallback(async () => {
		setIsLoading(true);
		let tokensId: number[] = [];
		for (let i = 0; i < 99999; i++) {
			let tokenId;
			try {
				tokenId = {...(await MMPROLabsContract.methods.nftsForSale(i).call())}.tokenId;
			} catch (e) {
				setIsLoading(false);
				break;
			} finally {
				setTimeout(() => {
					setIsLoading(false);
				}, 3000);
			}
			tokensId.push(+tokenId);
			setTokensIdFromSale(tokensId);
		}
	}, []);

	const getStakePercent = useCallback(() => {
		if (+token?.lockId === 0) {
			return "1 month: 10%";
		} else if (+token?.lockId === 1) {
			return "6 month: 20%";
		} else {
			return "12 month: 30%";
		}
	}, [token]);

	const getEndOfStakeTime = useCallback(() => {
		let date;

		if (+token?.lockId === 0) {
			date = new Date((+token.lockedAt + 2630000) * 1000);
		} else if (+token.lockId === 1) {
			date = new Date((+token.lockedAt + (2630000 * 6)) * 1000);
		} else {
			date = new Date((+token.lockedAt + (2630000 * 12)) * 1000);
		}

		const year = date.getFullYear();
		const month = ("0" + (date.getMonth() + 1)).slice(-2);
		const day = ("0" + date.getDate()).slice(-2);

		return `${day}.${month}.${year}`;
	}, [token]);

	const checkIsTokenOnSale = useCallback(() => {
		setIsTokenOnSale(tokensIdFromSale.some((tokenId) => tokenId === +params.id));
	}, [params, tokensIdFromSale.length]);

	const getTerminalAmountShares = async () => {
		try {
			const amount = await ChangeNFTContract.methods.getTerminalAmount(+params.id).call();
			setTerminalSharesAmount(amount)
		} catch (e) {
			console.log(e);
		}
	}

	const getTrustAmountShares = async () => {
		try {
			const amount = await ChangeNFTContract.methods.getTrustAmount(+params.id).call();
			setTrustSharesAmount(amount)
		} catch (e) {
			console.log(e);
		}
	}

	const swapNFTForTerminal = async () => {
		setIsTerminalSwapLoading(true)
		const gasPrice = await web3.eth.getGasPrice();
		try {
			await ChangeNFTContract.methods.swapNFTforTerminal(+params.id)
				.send({from: account, gasPrice: gasPrice})
				.once("receipt", () => {
				notificationContext.displayNotification(
					`NFT exchanged successfully`,
					``,
					<NotificationIcon/>
				);
			});
		} catch (e) {
			console.log(e);
			notificationContext.displayNotification(
				`Something went wrong`,
				"",
				<NotificationIcon/>
			);
		} finally {
			setIsTerminalSwapLoading(false)
		}
	}

	const swapNFTForTrust = async () => {
		setIsTrustSwapLoading(true)
		const gasPrice = await web3.eth.getGasPrice();
		try {
			await ChangeNFTContract.methods.swapNFTforTrust(+params.id)
				.send({from: account, gasPrice: gasPrice})
				.once("receipt", async () => {
				notificationContext.displayNotification(
					`NFT exchanged successfully`,
					``,
					<NotificationIcon/>
				);
				await axios.post('https://backend.nft-store.marketmaking.pro/api/shares', {
					sharesName: 'Trust',
					wallet: account,
					amount: trustSharesAmount,
					email
				})
			});
		} catch (e) {
			console.log(e);
			notificationContext.displayNotification(
				`Something went wrong`,
				"",
				<NotificationIcon/>
			);
		} finally {
			setIsTrustSwapLoading(false)
		}
	}


	const approveSwap = async (isTerminal: boolean) => {
		setIsApproveLoading(true)
		try {
			await MMPROLabsContract.methods.approve(getChangeNFTContract(), +params.id).send({from: account}).once("receipt", () => {
				if (isTerminal) {
					swapNFTForTerminal()
				} else {
					swapNFTForTrust()
				}
			})
		} catch (e) {
			console.log(e)
			notificationContext.displayNotification(
				`Something went wrong`,
				"",
				<NotificationIcon/>
			);
		} finally {
			setIsApproveLoading(false)
		}
	}

	const purchase = async () => {
		setIsTakeRewardLoading(true);
		const gasPrice = await web3.eth.getGasPrice()
		try {
			await MMPROLabsContract.methods.purchase(+params.id)
				.send({ from: account, gasPrice: gasPrice })
				.once("receipt", () => {
				notificationContext.displayNotification(
					`Reward has been received`,
					`Check MMPRO tokens on the wallet`,
					<NotificationIcon/>
				);
			});
		} catch (e) {
			console.log(e);
			notificationContext.displayNotification(
				`Something went wrong`,
				"",
				<NotificationIcon/>
			);
		} finally {
			setIsTakeRewardLoading(false);
		}
	};
	const takeFromSale = async () => {
		setIsTakeFromSaleLoading(true);
		const gasPrice = await web3.eth.getGasPrice();
		try {
			await MMPROLabsContract.methods.takeFromSales(+params.id)
				.send({from: account, gasPrice: gasPrice})
				.once("receipt", () => {
				notificationContext.displayNotification(
					`NFT removed from sale`,
					``,
					<NotificationIcon/>
				);
				setIsTokenOnSale(false);
			});
		} catch (e) {
			console.log(e);
			notificationContext.displayNotification(
				`Something went wrong`,
				"",
				<NotificationIcon/>
			);
		} finally {
			setIsTakeFromSaleLoading(false);
		}
	};
	const checkIsUserCanUnlock = async () => {
		try {
			const isUserCanUnlock = await MMPROLabsContract.methods.canUnlock(+params.id).call();
			setIsUserCanUnlock(isUserCanUnlock);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		checkIsTokenOnSale();
	}, [params, tokensIdFromSale.length]);

	useEffect(() => {
		getTokenInfo();
		getTokensFromSale();
		getTerminalAmountShares();
		getTrustAmountShares();
		checkIsUserCanUnlock();
	}, []);

  if (!token) {
    return <NoPageError />
  }

  return (
    <Container>
      <NFTMarketplaceSubHeader subtitle={"SALE"} />
      <ReturnBackPanel href={`/collection`} />
      {
        isLoading ?
          <Spinner color={"#33CC66"} size={25} />
          :
          <>
            {
              token &&
              <NFTCardWrapper>
                <StyledImgWrapper>
                  <StyledImg src={isIvend ? `/images/ivendNfts/Tier${+token.tier+1}.png` : "/images/LabsCover.png"} />
                  {!isIvend &&
                    <Text fontWeight={400} fontSize={18} color={"rgba(24, 24, 51, 0.4)"} adaptiveFontSize={16}>MMPro
                      Labs
                      is an
                      exclusive investors
                      club that brings you special conditions and opportunities.
                    </Text>
                  }
                </StyledImgWrapper>
                <ContentWrapper>
                  {isIvend &&
                    <div>
                      <TextWrapper gap={5}>
                        <Text fontWeight={600} fontSize={24}
                              color={"rgba(24, 24, 51)"}>{(+token.priceInUSDT / 10 ** 18).toFixed(0)}</Text>
                        <Text fontWeight={400} fontSize={20} color={"rgba(24, 24, 51)"}
                              adaptiveFontSize={14}>USDT</Text>
                      </TextWrapper>
                    </div>
                  }
                  {!isIvend &&
                    <div>
                      <Text fontWeight={600} fontSize={24} color={"rgba(24, 24, 51)"}>LABS NFT</Text>
                      <div className={"mb-4"} />
                      <Text fontWeight={600} fontSize={20} color={"rgba(24, 24, 51)"}
                            adaptiveFontSize={16}>Staked</Text>
                      <div className={"mb-1"} />
                      <TextWrapper gap={5}>
                        <Text fontWeight={600} fontSize={24}
                              color={"rgba(24, 24, 51)"}>{(token.price / 10 ** 18).toFixed(0)}</Text>
                        <Text fontWeight={400} fontSize={20} color={"rgba(24, 24, 51)"}
                              adaptiveFontSize={14}>MMPRO</Text>
                      </TextWrapper>
                      <div className={"mb-4"} />
                      <TextWrapper gap={10}>
                        <Text fontWeight={600} fontSize={16} color={"rgba(24, 24, 51,.5)"}
                              adaptiveFontSize={14}>Staking
                          level</Text>
                        <Text fontWeight={600} fontSize={18} color={"rgba(24, 24, 51)"}
                              adaptiveFontSize={18}>{getStakePercent()}</Text>
                        {+token?.lockId !== 2 &&
                          <SecondaryButton
                            isValid={isUserCanUnlock}
                            onClick={() => setChangePeriodModalActive(true)}
                            isBordered={false}
                          >
                            <Text fontWeight={400} fontSize={16} color={'#33CC66'}>Change</Text>
                          </SecondaryButton>
                        }
                      </TextWrapper>
                      <TextWrapper gap={5}>
                        <Text fontWeight={600} fontSize={16} color={"rgba(24, 24, 51,.5)"}
                              adaptiveFontSize={14}>End of
                          staking</Text>
                        <Text fontWeight={600} fontSize={18} color={"rgba(24, 24, 51)"}
                              adaptiveFontSize={18}>{getEndOfStakeTime()}</Text>
                        <SecondaryButton
                          isValid={isUserCanUnlock}
                          onClick={() => setUnlockModalActive(true)}
                          isBordered={false}
                        >
                          <Text fontWeight={400} fontSize={16} color={"#33CC66"}>Unlock</Text>
                        </SecondaryButton>
                      </TextWrapper>
                      <div className={"mb-5"} />
                      <Line />
                      <div className={"mb-5"} />
                      <IconAndTextWrapper>
                        <TokensRecievedIcon />
                        <ColumnTextWrapper>
                          <Text fontWeight={600} fontSize={20} color={"rgba(24, 24, 51)"}>Your rewards</Text>
                          {
                            +token.cumulatedReward ?
                              <TextWrapper gap={8}>
                                <Text fontWeight={600} fontSize={24}
                                      color={"#33CC66"}>{(+token.cumulatedReward / 10 ** 18).toFixed(1)}</Text>
                                <Text fontWeight={400} fontSize={20} color={"#33CC66"}>MMPRO</Text>
                              </TextWrapper>
                              :
                              <Text fontWeight={400} fontSize={16} color={"rgba(24, 24, 51, 0.4)"}>Rewards not calculated
                                yet.</Text>
                          }
                        </ColumnTextWrapper>
                      </IconAndTextWrapper>
                    </div>
                  }
                  <div>
                    {!isIvend &&
                      <ButtonV2
                        isValid={+token?.cumulatedReward / 10 ** 18 > 0}
                        onClick={+token?.cumulatedReward / 10 ** 18 > 0 ? () => purchase() : () => {
                        }}
                      >
                        <WihdrawButtonContentWrapper>
                          {
                            isTakeRewardLoading ?
                              <Spinner color={"#fff"} size={25} />
                              :
                              <>
                                <WithdrawButtonIcon />
                                <Text fontWeight={500} fontSize={16} color={"#fff"}>Claim rewards</Text>
                              </>
                          }
                        </WihdrawButtonContentWrapper>
                      </ButtonV2>
                    }
                    <div className={"mb-5"} />
                    <SecondaryButtonsWrapper>
											{
												isTokenOnSale ?
													<SecondaryButton isBordered onClick={takeFromSale} isValid>
														{
															isTakeFromSaleLoading ?
																<Spinner color={"#33CC66"} size={25}/>
																:
																<>
																	<SaleIcon/>
																	<Text fontWeight={400} fontSize={16} color={"#33CC66"}>Remove from sale</Text>
																</>
														}
													</SecondaryButton>
													:
													<>
														<SecondaryButton
															isValid
															onClick={() => setSaleLabsModalActive(true)}
															isBordered={false}
														>
															<SaleIcon/>
															<Text fontWeight={400} fontSize={16} color={"#33CC66"}>Sell</Text>
														</SecondaryButton>
														<SecondaryButton
															isValid
															onClick={() => setTransferLabsModalActive(true)}
															isBordered={false}
														>
															<HandOverIcon/>
															<Text fontWeight={400} fontSize={16} color={"#33CC66"}>Transfer</Text>
														</SecondaryButton>
													</>
											}
                    </SecondaryButtonsWrapper>
                  </div>
                </ContentWrapper>
              </NFTCardWrapper>
						}
					</>
			}
			{
				saleLabsModalActive &&
        <Modal onClose={setSaleLabsModalActive}>
          <SaleLabsNftModal/>
        </Modal>
			}
			{
				transferLabsModalActive &&
        <Modal onClose={setTransferLabsModalActive}>
          <TransferLabsNftModal/>
        </Modal>
			}
			{
				unlockModalActive &&
        <Modal onClose={setUnlockModalActive}>
          <ChangeStakePeriodModal
            reward={+token.cumulatedReward}
            lockId={+token.lockId}
            endOfStaking={getEndOfStakeTime()}
          />
        </Modal>
			}
			{
				changePeriodModalActive &&
        <Modal onClose={setChangePeriodModalActive}>
          <LabsModal
            isActiveInCollection
            lockId={+token.lockId}
          />
        </Modal>
			}
		</Container>
	);
};

CurrentLabsNft.defaultProps = CurrentLabsNftDefaultProps;

export default CurrentLabsNft;
