import React, {useContext} from "react";
import styled from "styled-components";
import {Token} from "../../types";
import {wei2eth} from "../../Standard/utils/common";
import Text from '../Text'
import texts from './localization'
import {localized} from "../../Standard/utils/localized";
import LocaleContext from "../../Standard/LocaleContext";

type NFTTransferFormPropType = {
  totalUserAllocationUSDT: string
  totalUserAllocationBUSD: string
}

const NFTTransferFormDefaultProps = {}

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 7px;
`

const NFTTransferForm = (props: NFTTransferFormPropType) => {
  const {totalUserAllocationUSDT, totalUserAllocationBUSD} = props
  const {locale} = useContext(LocaleContext)

  return (
    <TextWrapper>
      <Text fontWeight={600} fontSize={20}>{localized(texts.finalAllocation, locale)}</Text>
      {+totalUserAllocationUSDT > 0 &&
        <FlexWrapper>
          <Text fontWeight={600} fontSize={24}>{totalUserAllocationUSDT}</Text>
          <Text fontWeight={400} fontSize={22}>USDT</Text>
        </FlexWrapper>
      }
      {+totalUserAllocationBUSD > 0 &&
        <FlexWrapper>
          <Text fontWeight={600} fontSize={24}>{totalUserAllocationBUSD}</Text>
          <Text fontWeight={400} fontSize={22}>BUSD</Text>
        </FlexWrapper>
      }
    </TextWrapper>
  )
};

NFTTransferForm.defaultProps = NFTTransferFormDefaultProps

export default NFTTransferForm