import React, {useContext, useState} from 'react';
import styled from "styled-components";
import {localized} from "Standard/utils/localized";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import Text from "../../Text";
import EarnBackgroundText from 'components/MMPROLabs/images/EarnDescriptionText.svg'
import EarnDescriptionDecoration from 'components/MMPROLabs/images/EarnDescriptionDecoration.svg'
import EarnDescriptionStoreScreen from 'components/MMPROLabs/images/EarnDescriptionStoreScreen.svg'
import RewardsPoolModal from "../RewardsPoolModal";
import Modal from "Standard/components/Modal";
import MMProToken from 'components/MMPROLabs/images/MMProToken.svg'

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  overflow: hidden;
  padding-top: 90px;

  @media screen and (max-width: 1300px) {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    min-height: 0;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin-bottom: 80px;

  @media screen and (max-width: 1300px) {
    text-align: center;
    margin-bottom: 30px;
  }
`

const BackgroundImage = styled.div`
  position: absolute;
  width: 140%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  z-index: 1;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`

const DecorationImage = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`

const StoreScreen = styled.div`
  position: absolute;
  bottom: 0;
  right: 300px;
  z-index: 2;

  @media screen and (max-width: 1300px) {
    display: none;
  }
`

const MainTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1300px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 470px;
  gap: 10px;
  margin-left: 250px;

  @media screen and (max-width: 1300px) {
    margin-left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: none;
  }
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  background: #33CC66;
  border-radius: 40px;
  cursor: pointer;
  outline: none !important;
  font-weight: 400;
  font-size: 16px;
  color: #fff;
`

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;

  @media screen and (max-width: 1300px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

const TokenTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
`

const AlignCenterWrapper = styled.div`
  @media screen and (max-width: 1300px) {
    text-align: center;
  }
`

const TokenWrapper = styled.div`
  @media screen and (max-width: 1300px) {
    width: 160px;
  }
`

const HowItWorks = () => {
  const {locale} = useContext(LocaleContext)
  const [isModalActive, setIsModalActive] = useState(false)
  return (
    <Container>
      <StoreScreen>
        <img src={EarnDescriptionStoreScreen} alt="Store Screen"/>
      </StoreScreen>
      <DecorationImage>
        <img src={EarnDescriptionDecoration} alt=""/>
      </DecorationImage>
      <BackgroundImage>
        <img src={EarnBackgroundText} alt="How much can you earn?"/>
      </BackgroundImage>
      <TitleWrapper>
        <Text
          fontWeight={700}
          fontSize={32}
          color={'#33CC66'}
          adaptiveFontSize={25}
        >
          {localized(texts.howMuchCanYouEarn, locale)}
        </Text>
      </TitleWrapper>
      <TextWrapper>
        <MainTitleWrapper>
          <Text fontWeight={700} color={'#33CC66'} fontSize={48} adaptiveFontSize={32}>5%-10% FROM</Text>
          <Text fontWeight={700} color={'#33CC66'} fontSize={48} adaptiveFontSize={32}>MMPRO RWA Store</Text>
        </MainTitleWrapper>
        <AlignCenterWrapper>
          <Text fontWeight={500} fontSize={20} adaptiveFontSize={16}>
            {localized(texts.earnDetailText, locale)}
          </Text>
        </AlignCenterWrapper>
        <AlignCenterWrapper>
          <Text fontWeight={500} fontSize={16} color={'rgba(33, 33, 33, .5)'} adaptiveFontSize={15}>
            {localized(texts.earnDisclosure, locale)}
          </Text>
        </AlignCenterWrapper>
        <ButtonsWrapper>
          <Button onClick={() => setIsModalActive(true)}>{localized(texts.howMuchCanIEarn, locale)}</Button>
          <TokenTextWrapper>
            <img src={MMProToken} alt=""/>
            <TokenWrapper>
              <Text fontWeight={600} fontSize={16} color={'#33CC66'} textTransform={'uppercase'}>
                {localized(texts.Rewards, locale)}
              </Text>
            </TokenWrapper>
          </TokenTextWrapper>
        </ButtonsWrapper>
      </TextWrapper>
      {isModalActive &&
        <Modal onClose={setIsModalActive}>
          <RewardsPoolModal/>
        </Modal>
      }
    </Container>
  );
};

export default HowItWorks;
