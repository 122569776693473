import React, {useContext} from 'react';
import {localized} from "Standard/hooks/localized";
import LocaleContext from "Standard/LocaleContext";
import {NavItemsEnum} from "types/NavItem";
import texts from './localization'
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import Star from 'components/MMPROLabs/images/Star';

const StyledLink = styled.div`
  color: #181833;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 50px;
  letter-spacing: 2px;
  position: relative;
  transition: all 0.3s 0.2s ease;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background: #33CC66;
    bottom: -4px;
    border-radius: 4px;
    transition: all 0.4s cubic-bezier(0.82, 0.02, 0.13, 1.26);
    left: 100%;
  }

  &:hover {
    opacity: 1;
    color: #33CC66;

    &:before {
      width: 40px;
      left: 0;
    }
  }
  
  @media screen and (max-width: 1200px) {
    font-size: 12px;
  }
`

const StarWrapper = styled.div`
  position: absolute;
  width: 17px;
  height: 17px;
  top: -10px;
  right: -10px;
  animation: animate_grow 1s infinite;
  
  
  @keyframes animate_grow {
    0% {
      transform: scale3d(1, 1, 1);
    }
    50% {
      transform: scale3d(1.2, 1.2, 1.2);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
`

type NavItemProps = {
  title: NavItemsEnum,
  route: string,
  isDecorated: boolean;
}


const NavItem = (props: NavItemProps) => {
  const {title, route, isDecorated} = props
  const {locale} = useContext(LocaleContext)
  const history = useHistory()

  return (
    <StyledLink onClick={() => history.push(route)}>
      {
        isDecorated  &&
        <StarWrapper>
          <Star width={17} height={17} fill={'#33CC66'} />
        </StarWrapper>
      }
      {title}
    </StyledLink>
  )
};

export default NavItem;