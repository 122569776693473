export default {
  mmproLabs: {
    en: 'MMPRO LABS',
    ja: 'MMPRO LABS'
  },
  club: {
    en: 'EXCLUSIVE INVESTORS CLUB',
    ja: ''
  },
  description: {
    en: 'Become 1 of 100 revenue sharing NFT holders and share up to 10% from the RWA Store revenue',
    ja: ''
  },
  mintNft: {
    en: 'Apply for MMPro Labs 100 Limited',
    ja: ''
  }
}
