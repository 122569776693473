export const getAllocationMarketplaceContract = () => {
  return '0x9ECF19266921829c3Aa72722586e42E8d9Df5492'
}

export const getMMProLabsContract = () => {
  return '0xb23C0A9beB4af29eb09244710F442Ec7D2F98fc7'
}

export const getChangeNFTContract = () => {
  return '0x3998aE7972fE3A5C01f414c8cdBaF23c6A52C250'
}

export const getIvendContract = () => {
  return '0x7eA0d74194e160eFF647008aF2e888E6e25eE557'
}

// labs contract for test 0x7a92f53a66412b0a9b2699A1911c8cE34Edb5641
// labs contract for prod 0xb23C0A9beB4af29eb09244710F442Ec7D2F98fc7

