export default {
  howItWorks: {
    en: 'HOW IT WORKS ?',
    ja: ''
  },
  buy: {
    en: 'Buy',
    ja: ''
  },
  aLotOfTokens: {
    en: '200K MMPro tokens',
    ja: ''
  },
  lock: {
    en: 'Lock',
    ja: ''
  },
  lockText: {
    en: 'them for 1 or 6 or 12 months',
    ja: ''
  },
  get: {
    en: 'Get',
    ja: ''
  },
  exclusiveNft: {
    en: 'Exclusive NFT',
    ja: ''
  },
  shareRevenue: {
    en: 'Share revenue',
    ja: ''
  },
  shareText: {
    en: 'from MMPro RWA Store Revenue',
    ja: ''
  },
}
