import React, {useContext} from 'react';
import {RowCentered, JustifyCenterColumn} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import './index.css';
import Text from 'components/Text'
import {localized} from "../../../Standard/hooks/localized";
import texts from './localization'
import LocaleContext from "../../../Standard/LocaleContext";

const ChooseTierBlock = styled(RowCentered)`
  border-radius: 30px;
  background: #fff;
  width: 440px;
  height: max-content;
  padding: 45px;
  gap: 15px;
  
  @media screen and (max-width: 1200px) {
    width: 300px
  }
`

const TextWrapper = styled.div`
  text-align: center;
`

const RewardsPoolModal = () => {
  const {locale} = useContext(LocaleContext)

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock>
        <Text fontWeight={700} fontSize={24} color={'#33CC66'}>{localized(texts.howItWorks, locale)}</Text>
        <TextWrapper>
          <Text fontWeight={500} fontSize={20} color={'#000'}>{localized(texts.monthlyIncome, locale)}</Text>
        </TextWrapper>
        <JustifyCenterColumn>
          <TextWrapper>
            <Text fontWeight={400} fontSize={18} color={'#000'}>{localized(texts.point1, locale)}</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={18} color={'#000'}>{localized(texts.point2, locale)}</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={18} color={'#000'}>{localized(texts.point3, locale)}</Text>
          </TextWrapper>
          <TextWrapper>
            <Text fontWeight={400} fontSize={18} color={'#000'}>{localized(texts.point4, locale)}</Text>
          </TextWrapper>
        </JustifyCenterColumn>
        <JustifyCenterColumn gap={10}>
            <TextWrapper>
              <Text fontWeight={400} fontSize={14} color={'rgba(0, 0, 0, 0.6)'}>
                {localized(texts.rewardsPool, locale)}
              </Text>
            </TextWrapper>
            <TextWrapper>
              <Text fontWeight={400} fontSize={14} color={'rgba(0, 0, 0, 0.6)'}>
                {localized(texts.incomeAndPool, locale)}
              </Text>
            </TextWrapper>
        </JustifyCenterColumn>
      </ChooseTierBlock>
    </JustifyCenterColumn>
  );
};

export default RewardsPoolModal;