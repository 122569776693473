import {useHistory} from "react-router-dom";
import React, {useContext} from "react";
import LocaleContext from "../../Standard/LocaleContext";
import button from "../../Standard/components/Button";
import {localized} from "../../Standard/utils/localized";
import texts from "./localization";
import CollectionIcon from 'Standard/icons/CollectionIcon'

const CollectionButton = () => {
  const history = useHistory()
  const {locale} = useContext(LocaleContext)

  return (
    <button
      className={`connection-button`}
      onClick={() => history.push('/collection')}
    >
      <CollectionIcon/>
      <div style={{marginRight: 12}}/>
      {localized(texts.collection, locale)}
    </button>
  )
}

export default CollectionButton;