import React, {useContext, useEffect, useState} from "react";
import texts from './localization'
import LocaleContext from "../../Standard/LocaleContext";
import {localized} from "../../Standard/utils/localized";
import {useWeb3React} from "@web3-react/core";
import ButtonV2 from "../../Standard/components/ButtonV2";
import SimpleLabelContainer from "../../Standard/components/SimpleLabelContainer";
import SimpleInput from "../../Standard/components/SimpleInput";
import {wei2eth} from "../../Standard/utils/common";
import Documents from "../Documents";
import {CurrentToken, NFT, ProjectToken, Token} from "../../types";
import styled from "styled-components";
import Spinner from "../../Standard/components/Spinner";
import fromExponential from "from-exponential";
import {
	useBUSDContract,
	usePancakeRouterContract,
	useUSDTContract,
	useWeb3
} from "../../Standard/hooks/useCommonContracts";
import useValidatedState, {validationFuncs} from "../../Standard/hooks/useValidatedState";
import BigNumber from "bignumber.js";
import NotificationIcon from "../../Standard/icons/notificationIcon";
import NotificationContext from "../../Standard/utils/NotificationContext";
import {getBUSDAddress, getMMProAddress} from "../../Standard/utils/getCommonAdress";
import {useNftContract} from "../../hooks/useNftContract";
import CollectionContext from "../../utils/CollectionContext";
import Notification from "../Notification";
import Text from "../Text";
import axios from "axios";
import {useParams} from "react-router-dom";


const SLIPPAGE_PERCENT = 0.93

const DEADLINE_OVER_NOW = 1000

type CurrentNFTBuyFormPropType = {
	token: ProjectToken | undefined;
	tokenContractInfo: CurrentToken | undefined
	updateBalance: () => void;
	balance: string;
	projectAddress: string | undefined
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
`

const CurrentNFTBuyFormDefaultProps = {}

const SpinnerContainer = styled.div`
  position: absolute;
  right: 10px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const CurrentNFTBuyForm = (props: CurrentNFTBuyFormPropType) => {
	const params: { id: string, nftId: string } = useParams();
	const {token, tokenContractInfo, updateBalance, balance, projectAddress} = props
	const {account, active} = useWeb3React()
	const web3 = useWeb3()
	const notificationContext = useContext(NotificationContext)
	const {locale} = useContext(LocaleContext)
	const collectionContext = useContext(CollectionContext)

	// const usdtContract = useusdtContract()
	const usdtContract = useUSDTContract()
 	const pancakeRouterContract = usePancakeRouterContract();
	const isSkeleton = !projectAddress || !token
	const currentNftContract = useNftContract(projectAddress ? projectAddress : undefined)

	const [isLoading, setIsLoading] = useState(false)
	const [allowance, setAllowance] = useState<string>('0')
	const [isApproveLoading, setIsApproveLoading] = useState(false)

	const [[email, setEmail], emailValid] = useValidatedState<string>("", validationFuncs.isEmail)
	const [[allocationAmountBusd, setAllocationAmountBusd], allocationAmountBusdValid] = useValidatedState<string>('', newValue => !isNaN(+newValue) && (!tokenContractInfo || +newValue <= +tokenContractInfo.maxAllocation))

	const [isDocValid, setIsDocValid] = useState(false)
	const isValid =
		token &&
		tokenContractInfo &&
		Boolean(projectAddress) &&
		emailValid &&
		allocationAmountBusdValid &&
		email !== undefined &&
		allocationAmountBusd !== undefined &&
		email !== '' &&
		allocationAmountBusd !== '' &&
		isDocValid &&
		new BigNumber(allocationAmountBusd).multipliedBy(1000000000000000000).isLessThanOrEqualTo(+tokenContractInfo.maxAllocation * 10 ** 18)

	const getDeadline = () => {
		return Math.floor(new Date().getTime() / 1000) + DEADLINE_OVER_NOW;
	}
	const getAllowance = async (): Promise<string> => {
		return await usdtContract
			.methods
			// @ts-ignore
			.allowance(account, projectAddress)
			.call()
	}
	async function updateAllowance() {
		if (token && tokenContractInfo && projectAddress) {
			const newAllowance = await getAllowance()
			setAllowance(newAllowance)
		}
	}
	const approve = async () => {
		if (isApproveLoading) {
			return
		}

		// Max INT allowance
		const ALLOWANCE = web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1));
		const gasPrice = await web3.eth.getGasPrice()
		setIsApproveLoading(true)
		try {
			if (token) {
				await usdtContract
					.methods
					.approve(projectAddress, ALLOWANCE)
					.send({ from: account, gasPrice: gasPrice })
					.once('receipt', () => {
						updateAllowance()
						setIsApproveLoading(false)
					});
			}
		} catch (e) {
			setIsApproveLoading(false)
		} finally {
			setIsApproveLoading(false)
		}
	};
	const isApprovalRequired = () => !isSkeleton && !!tokenContractInfo && (parseInt(allowance) < parseInt((+tokenContractInfo.price * 10 ** 18).toString()))
	const getMinAmountOut = async () => {
		const path = [getBUSDAddress(), getMMProAddress()]
		return new BigNumber((await pancakeRouterContract
			.methods
			.getAmountsOut(allocationAmountBusd, path)
			.call())[1])
	}

	const sendDataToTrust = async (txId: string) => {
		try {
			await axios.post('https://back2.kyc.marketmaking.pro/api/deals/marketplace', {
				"email": email,
				"projectUuid": params.id,
				"nftPrice": tokenContractInfo ? +tokenContractInfo.price : 0,
				"allocation": +allocationAmountBusd,
				"walletAddress": account,
				"txHash": txId
			})
		} catch (e) {
			console.log(e)
		}
	}

	async function mintAndAllocate() {
		if (isValid) {
			const tokenId = token.nftId
			const amountOutMin = (await getMinAmountOut()).multipliedBy(SLIPPAGE_PERCENT).toFixed(0).toString()
			const allocationAmount = (new BigNumber(10).pow(18).multipliedBy(+allocationAmountBusd)).toString()
			const deadline = getDeadline()
			const gasPrice = await web3.eth.getGasPrice()

			try {
				if (currentNftContract && tokenId.toString() && amountOutMin && allocationAmount && deadline) {
					await currentNftContract
						.methods
						.mintAndAllocate(
							tokenId.toString(),
							amountOutMin,
							deadline,
							allocationAmount,
							[]
						)
						.send({ from: account, gasPrice: gasPrice })
						.once('receipt', (receipt: any) => {
							const txId = receipt.transactionHash;
							setIsLoading(false)
							notificationContext.displayNotification(
								`${localized(texts.successNotification, locale)} ${+allocationAmountBusd + +tokenContractInfo?.price} BUSD`,
								'',
								<NotificationIcon/>
							)
							setEmail('')
							setAllocationAmountBusd('')
							collectionContext.setCollectionBubbleValue(collectionContext.bubbleCount + 1)
							sendDataToTrust(txId)
						});
				}
			} catch (e) {
				console.log(e)
				notificationContext.displayNotification(
					`${localized(texts.transactionFailedTitle, locale)}`,
					`${localized(texts.transactionFailedSubtitle, locale)}`,
					<NotificationIcon/>
				)
				setIsLoading(false)
			}
		}
	}
	const handleBuy = async () => {
		const isBalanceValid =
			allocationAmountBusd &&
			tokenContractInfo &&
			(+allocationAmountBusd + parseInt(tokenContractInfo.price) <= parseInt(wei2eth(balance).toString()))

		if (!isBalanceValid) {
			notificationContext.displayNotification(
				localized(texts.notificationInsufficientTitle, locale),
				'',
				<NotificationIcon/>
			)
			return
		}

		if (isLoading) {
			return
		}

		if (!isValid && !isApprovalRequired()) {
			return
		}

		setIsLoading(true)
		try {
			await mintAndAllocate()
			await updateBalance()
		} catch (e) {
			console.log({error: e})
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (active) {
			updateAllowance()
			updateBalance()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active, token, projectAddress])

	return (
		<>
			{(!account && !isSkeleton) && <Notification body={localized(texts.connectWalletToAllocate, locale)}/>}
			{(account && isApprovalRequired()) &&
          <ButtonWrapper>
              <ButtonV2
                  isValid
                  outlined
                  onClick={isSkeleton ? () => {
									} : approve}
              >
								{localized(texts.approveButton, locale)}
								{isApproveLoading &&
                    <SpinnerContainer>
                        <Spinner color={'#33CC66'} size={25}/>
                    </SpinnerContainer>
								}
              </ButtonV2>
		          <div className={'mb-2'} />
              <Text fontWeight={400} fontSize={14} color={'#181833'}>
                  With the latest update of Metamask, you are now required to specify the amount of money that you allow
                  our smart contract to spend. Therefore, we recommend that you enter a sufficient amount to make
                  multiple purchases.
              </Text>
          </ButtonWrapper>
			}
			{(account && !isApprovalRequired()) &&
          <Wrapper>
              <SimpleLabelContainer
                  label={localized(texts.placeholderEmail, locale)}
              >
                  <SimpleInput
                      onlyEmmitOnBlur
                      onChangeRaw={setEmail}
                      isValid={email === "" || emailValid}
                      required
                      errorTooltipText={localized(texts.tooltipPleaseEnterCorrectEmail, locale)}
                      autoComplete="email"
                      inputProps={{
												className: `w-full ${isSkeleton ? "skeleton" : ''}`,
												placeholder: localized(texts.placeholderEmail, locale),
												type: 'email',
												value: email
											}}
                  />
              </SimpleLabelContainer>
              <SimpleLabelContainer
                  label={localized(texts.placeholderYourAllocation, locale)}
              >
                  <SimpleInput
                      onlyEmmitOnBlur
                      hasDefaultValueButton
                      defaultValueButtonText={localized(texts.defaultButtonMax, locale)}
                      defaultValue={wei2eth(token ? +token.maxAllocation * 10 ** 18 : 0).toString()}
                      required
                      onChangeRaw={setAllocationAmountBusd}
                      isValid={allocationAmountBusd === "" || allocationAmountBusdValid}
                      errorTooltipText={localized(texts.tooltipAmountCannotExceedMaxAllocation, locale)}
                      inputProps={{
												className: `w-full ${isSkeleton ? "skeleton" : ''}`,
												placeholder: localized(texts.placeholderYourAllocation, locale),
												value: allocationAmountBusd
											}}
                  />
              </SimpleLabelContainer>
              <Documents email={email} setIsDocValid={setIsDocValid}/>
              <ButtonV2
                  className={`${isSkeleton && 'skeleton'}`}
                  isValid={isValid}
                  outlined
                  onClick={isSkeleton ? () => {
									} : handleBuy}
              >
								{localized(texts.allocateButton, locale)}
                  <SpinnerContainer>
                      <Spinner color={'#33CC66'} size={isLoading ? 25 : 0}/>
                  </SpinnerContainer>
              </ButtonV2>
          </Wrapper>
			}
		</>
	)
};

CurrentNFTBuyForm.defaultProps = CurrentNFTBuyFormDefaultProps

export default CurrentNFTBuyForm

