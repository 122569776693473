export default {
  howItWorks:{
    en: 'How it works?',
    ja: '',
  },
  monthlyIncome:{
    en: 'NFT’s total monthly accrual of passive income is formed from:',
    ja: '',
  },
  point1:{
    en: '1. Total minted NFT(-s) amount',
    ja: '',
  },
  point2:{
    en: '2. Selected Lockup period for each NFT',
    ja: '',
  },
  point3:{
    en: '3. Staking Time',
    ja: '',
  },
  point4:{
    en: '4. Reward Pool capacity',
    ja: '',
  },
  rewardsPool:{
    en: 'Reward pool is distributed between all minted NFTs.',
    ja: '',
  },
  incomeAndPool:{
    en: 'NFT passive income is formed proportionately from the Reward Pool in accordance with the selected Lockup Period and Staking Time.',
    ja: '',
  },
}