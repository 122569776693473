import React, {useContext} from "react";
import LocaleContext from "../../../Standard/LocaleContext";
import texts from '../../../localization'
import SubHeader from "../index";
import {localized} from "../../../Standard/utils/localized";

type NFTMarketplaceSubHeaderPropType = {
  subtitle?: string,
  title?: string,
}

const NFTMarketplaceSubHeader = (props: NFTMarketplaceSubHeaderPropType) => {
  const {locale} = useContext(LocaleContext)
  return (
    <SubHeader
      title={props.title ? props.title : localized(texts.subheaderTitle, locale)}
      subtitle={props.subtitle}
    />
  )
};

export default NFTMarketplaceSubHeader