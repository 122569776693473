import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProjectContext from "../../utils/ProjectsContext";
import ReturnBackPanel from "../../components/ReturnBackPanel";
import CurrentProjectWrapper from "../../components/CurrentProjectWrapper";
import CurrentNFTGallery from "../../components/CurrentNFTGallery";
import CurrentNFTDescription from "../../components/CurrentNFTDescription";
import CurrentNFTBuyForm from "../../components/CurrentNFTBuyForm";

import { AllProjects } from "../../mocks/AllProjects";
import NFTMarketplaceSubHeader from "../../components/SubHeader/variants/NftMarketplace";
import GradientCircles from "../../Standard/decorations/GradientCircles";
import { CurrentProject, CurrentToken, Project, ProjectToken } from "../../types";
import { Contract } from "ethers";
import CurrentNftAbi from "../../contract/CurrentNft.json";
import { useWeb3React } from "@web3-react/core";
import { useWeb3 } from "../../Standard/hooks/useCommonContracts";
import {useBalanceOfBUSD, useBalanceOfUSDT} from "../../hooks/useBalance";
import { instance } from "../../api";
import NoPageError from "../../Standard/components/404";

const CurrentNFTContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
`;

const Container = styled.div`
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1500px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 900px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const CurrentNFTWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (max-width: 1240px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const CoverAndInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 2;
`;
const CurrentNFT = () => {
  const params: { id: string, nftId: string } = useParams();

  const [currentToken, setCurrentToken] = useState<ProjectToken | undefined>(undefined);
  const [otherTokens, setOtherTokens] = useState<ProjectToken[] | undefined>(undefined);
  const [currentProjectInfo, setCurrentProjectInfo] = useState<Project | undefined>(undefined);
  const [currentTokenContractInfo, setCurrentTokenContractInfo] = useState<CurrentToken | undefined>(undefined);
  const [currentNftLoading, setCurrentNftLoading] = useState<boolean>(true)
  const getCurrentNFT = async () => {
    setCurrentNftLoading(true)
    try {
      const response = await instance.get(`/nft?projectUuid=${params.id}&nftId=${params.nftId}`);
      setCurrentTokenContractInfo(response.data.data.tokensInfo);
    } catch (e) {
      console.log(e);
    } finally {
      setCurrentNftLoading(false)
    }
  };

  const getAllTokens = async () => {
    try {
      const response = await instance.get(`/nft?projectUuid=${params.id}`);
      const otherTokens = response.data.data.tokensInfo.filter((token: ProjectToken) => token.nftId !== +params.nftId);
      const currentToken = response.data.data.tokensInfo.filter((token: ProjectToken) => token.nftId === +params.nftId)[0];
      setOtherTokens(otherTokens);
      setCurrentToken(currentToken);
      setCurrentProjectInfo(response.data.data.project);
    } catch (e) {
      console.log(e);
    }
  };

  const { balance, updateBalance } = useBalanceOfUSDT();

  useEffect(() => {
    getAllTokens();
    getCurrentNFT();
  }, [+params.nftId]);

  useEffect(() => {
    if (currentToken) {
      getCurrentNFT();
    }
  }, [currentToken, +params.nftId]);

  if (!currentToken && !currentNftLoading) {
    return <NoPageError/>
  }

  return (
    <>
      <CurrentNFTContainer>
        <NFTMarketplaceSubHeader
          subtitle={currentProjectInfo?.name}
        />
        <Container>
          <ReturnBackPanel href={`/projects/${params.id}`} />
          <CurrentProjectWrapper project={currentProjectInfo}>
            <CurrentNFTWrapper>
              <CurrentNFTGallery
                otherTokens={otherTokens}
                currentToken={currentToken}
                projectId={currentProjectInfo?.uuid}
              />
              <CoverAndInfoWrapper>
                <CurrentNFTDescription
                  tokenTier={currentToken?.tier}
                  token={currentTokenContractInfo}
                  balance={balance}
                  updateBalance={updateBalance}
                />
                <CurrentNFTBuyForm
                  projectAddress={currentTokenContractInfo?.contractAddress}
                  token={currentToken}
                  tokenContractInfo={currentTokenContractInfo}
                  updateBalance={updateBalance}
                  balance={balance}
                />
              </CoverAndInfoWrapper>
            </CurrentNFTWrapper>
          </CurrentProjectWrapper>
        </Container>
      </CurrentNFTContainer>
      <GradientCircles />
    </>
  );
};

export default CurrentNFT;

