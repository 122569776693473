import React from 'react';

// Import colors from constants or use currentColor
// If icon uses more than one adjustable color you can pass colors from props
// BUT you must always provide default values for color props

export default () => {
    return (
      <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.89997 0.100098L19.799 1.5151L21.213 11.4151L12.021 20.6071C11.8334 20.7946 11.5791 20.8999 11.314 20.8999C11.0488 20.8999 10.7945 20.7946 10.607 20.6071L0.706971 10.7071C0.5195 10.5196 0.414185 10.2653 0.414185 10.0001C0.414185 9.73493 0.5195 9.48063 0.706971 9.2931L9.89997 0.100098ZM12.728 8.5861C12.9137 8.77179 13.1342 8.91907 13.3769 9.01954C13.6196 9.12001 13.8797 9.1717 14.1423 9.17165C14.405 9.1716 14.665 9.11982 14.9077 9.01927C15.1503 8.91872 15.3708 8.77135 15.5565 8.5856C15.7422 8.39984 15.8894 8.17933 15.9899 7.93666C16.0904 7.69398 16.1421 7.43389 16.142 7.17124C16.142 6.90859 16.0902 6.64852 15.9896 6.40588C15.8891 6.16324 15.7417 5.94279 15.556 5.7571C15.3702 5.57141 15.1497 5.42412 14.907 5.32366C14.6644 5.22319 14.4043 5.1715 14.1416 5.17155C13.6112 5.17164 13.1025 5.38245 12.7275 5.7576C12.3525 6.13275 12.1418 6.64151 12.1419 7.17195C12.142 7.7024 12.3528 8.21108 12.728 8.5861Z" fill="#33CC66"/>
      </svg>
    );
};
