import React, {useContext} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import WelcomeBlock from "components/MMPROLabs/Welcome";
import BottomBlock from "components/MMPROLabs/Bottom";
import HowItWorks from "components/MMPROLabs/HowItWorks";
import EarnDescriptionPage from "components/MMPROLabs/EarnDescriptionPage";
import Opportunities from "components/MMPROLabs/Opportunities";

type MMPROLabsPropType = {}

const MMPROLabsDefaultProps = {}

const MMPROLabs = (props: MMPROLabsPropType) => {
  const {locale} = useContext(LocaleContext)

  return (
    <>
      <div className="MMPROLabs">
        <div style={{position: 'relative'}}>
          <WelcomeBlock />
          <HowItWorks />
          <EarnDescriptionPage />
          <Opportunities />
        </div>
        <BottomBlock/>
      </div>
    </>
  )
};

MMPROLabs.defaultProps = MMPROLabsDefaultProps

export default MMPROLabs

