import React, {useContext, useState} from "react";
import texts from './localization'
import LocaleContext from "Standard/LocaleContext";
import {localized} from "Standard/utils/localized";
import './index.css'
import styled, {css} from "styled-components";
import Text from 'components/Text'
import {logger} from "ethers";

type LabsButtonPropType = {
  children: React.ReactNode
  onClick?: () => void,
  isValid: boolean
}

const LabsButtonDefaultProps = {}

const StyledButton = styled.button<{isValid: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #fff;
  border-radius: 40px;
  padding: 15px 40px;
  cursor: pointer;
  outline: none;

  ${({ isValid }) => !isValid && css`
    pointer-events: none;
  `};
  
  &:active,
  &:focus {
    outline: none;
  }
`

const LabsButton = (props: LabsButtonPropType) => {
  const {locale} = useContext(LocaleContext)
  const {children, onClick, isValid} = props

  return (
    <StyledButton onClick={onClick} isValid={isValid}>
      {children}
    </StyledButton>
  )
};

LabsButton.defaultProps = LabsButtonDefaultProps

export default LabsButton