import React, {useContext, useState} from 'react';
import {RowCentered, JustifyCenterColumn} from "Standard/styles/GlobalStyledComponents";
import styled from "styled-components";
import './index.css';
import Text from 'components/Text'
import SimpleLabelContainer from "Standard/components/SimpleLabelContainer";
import SimpleInput from "Standard/components/SimpleInput";
import useValidatedState, {validationFuncs} from "Standard/hooks/useValidatedState";
import ButtonV2 from "Standard/components/ButtonV2";
import {Checkbox} from "antd";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {useNFTLabsContract} from "hooks/useLabsContract";
import {useWeb3React} from "@web3-react/core";
import NotificationContext from "Standard/utils/NotificationContext";
import {useHistory, useParams} from "react-router-dom";
import NotificationIcon from "Standard/icons/notificationIcon";
import Spinner from "Standard/components/Spinner";
import {useIvendContract} from "../../../hooks/useIvendContract";

const ChooseTierBlock = styled(RowCentered)`
  border-radius: 30px;
  background: #fff;
  width: 600px;
  height: max-content;
  padding: 45px;
  gap: 15px;
  
  
  

  @media screen and (max-width: 700px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 340px;
    padding: 30px;
  }
`

const TransferLabsNftModal = () => {

  const {account} = useWeb3React()
  const isIvend = window.location.search.includes("ivend");


  const [[wallet, setWallet], walletValid] = useValidatedState<string>("", validationFuncs.isAddress)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const params: {id: string} = useParams()

  const MMPROLabsContract = useNFTLabsContract()
  const ivendContract = useIvendContract()

  const notificationContext = useContext(NotificationContext)
  const history = useHistory()

  const onChange = (e: CheckboxChangeEvent) => {
    setIsCheckboxChecked(e.target.checked)
  };

  const transfer = async () => {
    setIsLoading(true)

    const cotractToUse = isIvend ? ivendContract : MMPROLabsContract


    try {
      await cotractToUse.methods.transferFrom(account, wallet, +params.id).send({from: account}).once('receipt', () => {
        notificationContext.displayNotification(
          `Transfer was successful`,
          '',
          <NotificationIcon/>
        )
        history.push('/collection')
      });
    } catch (e) {
      notificationContext.displayNotification(
        `Something went wrong`,
        '',
        <NotificationIcon/>
      )
      setIsLoading(false)
      console.log(e)
    }
  }

  return (
    <JustifyCenterColumn>
      <ChooseTierBlock>
        <Text fontWeight={600} fontSize={28} color={'#33CC66'}>Transfer NFT</Text>
        <SimpleLabelContainer
          label={'Wallet'}
        >
          <SimpleInput
            onlyEmmitOnBlur
            onChangeRaw={setWallet}
            isValid={walletValid}
            required
            errorTooltipText={'Incorrect Wallet'}
            autoComplete="price"
            inputProps={{
              className: `w-full`,
              placeholder: '0x...',
              value: wallet
            }}
          />
        </SimpleLabelContainer>
        <Checkbox onChange={onChange} checked={isCheckboxChecked}>This wallet is on the BEP-20 network</Checkbox>
        <ButtonV2 isValid={walletValid && isCheckboxChecked} onClick={transfer}>
          {
            isLoading ?
            <Spinner color={'#fff'} size={25}/>
            :
            <span>Transfer</span>
          }
        </ButtonV2>
      </ChooseTierBlock>
    </JustifyCenterColumn>
  );
};

export default TransferLabsNftModal;