type AllProjectsDict = {[key: string]: {name: string, fakePrice: string, salePercentage: string, description: {}, priceForShare: string, creativeLink: string, isHidden?: boolean, presentationLink: string, nftsCreativeLinks: string[]}}

export const AllProjects: AllProjectsDict = {
  "Animoca Brands": {
    name: "Animoca Brands",
    priceForShare: "$3",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "/presentations/AnimocaBrands.pdf",
    description: {
      en: "Animoca Brands is a Hong Kong-based game software company and venture capital company founded in 2014 by Yat Siu. Animoca Brands' has over 340 cryptocurrency-related holdings.",
      ja: "Animoca Brands は、Yat Siu によって 2014 年に設立された、香港を拠点とするゲーム ソフトウェア会社およびベンチャー キャピタル会社です。 Animoca Brands は、340 以上の仮想通貨関連の資産を保有しています。"
    },
    creativeLink: '/creative/AnimocaBrands_cover.jpg',
    nftsCreativeLinks: [
      'creative/animocaBrands/t1.png',
      'creative/animocaBrands/t2.png',
      'creative/animocaBrands/t3.png',
      'creative/animocaBrands/t4.png',
      'creative/animocaBrands/t5.png',
    ]
  },
  "Circle": {
    name: "Circle",
    priceForShare: "$31.6",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "/presentations/AnimocaBrands.pdf",
    description: {
      en: "Circle is a financial technology company, founded in 2013, specializing in digital currency and blockchain technology. It is known for its stablecoin, USD Coin (USDC), which is pegged to the US dollar. Circle plays a crucial role in the cryptocurrency ecosystem, offering various financial services, including payments, trading, and investing. As of mid-summer 2022, Circle's market valuation is estimated to be significant, reflecting its impact and growing importance in the digital currency space.",
      ja: "Circle は、2013 年に設立されたデジタル通貨とブロックチェーン テクノロジーを専門とする金融テクノロジー企業です。 米ドルにペッグされたステーブルコイン USD Coin (USDC) で知られています。 Circle は、決済、取引、投資などのさまざまな金融サービスを提供し、暗号通貨エコシステムで重要な役割を果たしています。 2022 年半ばの時点で、Circle の市場評価は、デジタル通貨分野におけるその影響と重要性の高まりを反映して、相当なものになると推定されています。"
    },
    creativeLink: '/creative/AnimocaBrands_cover.jpg',
    nftsCreativeLinks: [
      'creative/animocaBrands/t1.png',
      'creative/animocaBrands/t2.png',
      'creative/animocaBrands/t3.png',
      'creative/animocaBrands/t4.png',
      'creative/animocaBrands/t5.png',
    ]
  },
  "Bitpay": {
    name: "Bitpay",
    priceForShare: "$7.65",
    fakePrice: "$8.5",
    salePercentage: "10%",
    presentationLink: "/presentations/Bitpay.pdf",
    description: {
      en: "BitPay is a bitcoin payment service provider. BitPay provides Bitcoin and Bitcoin Cash payment processing services for merchants.",
      ja: "BitPayは、ビットコイン決済サービスを提供する企業です。BitPayは、ビットコインとビットコインキャッシュの決済処理サービスをマーチャント向けに提供しています。"
    },
    creativeLink: '/creative/Bitpay_cover.jpg',
    nftsCreativeLinks: [
      'creative/bitpayNfts/t1.png',
      'creative/bitpayNfts/t2.png',
      'creative/bitpayNfts/t3.png',
      'creative/bitpayNfts/t4.png',
      'creative/bitpayNfts/t5.png',
    ]
  },
  "Metamask": {
    name: "MetaMask",
    priceForShare: "$105",
    fakePrice: "$150",
    salePercentage: "30%",
    presentationLink: "/presentations/ConsenSys.pdf",
    description: {
      en: "ConsenSys is a blockchain software technology company, the developer and owner of MetaMask. MetaMask is a software cryptocurrency wallet used to interact with the Ethereum blockchain.",
      ja: "ConsenSysはブロックチェーンソフトウェア技術企業であり、MetaMaskの開発者であり所有者です。MetaMaskは、Ethereumブロックチェーンと相互作用するために使用されるソフトウェア暗号通貨ウォレットです。"
    },
    creativeLink: '/creative/Metamask_cover.jpg',
    nftsCreativeLinks: [
      'creative/metamaskNfts/t1.png',
      'creative/metamaskNfts/t2.png',
      'creative/metamaskNfts/t3.png',
      'creative/metamaskNfts/t4.png',
      'creative/metamaskNfts/t5.png',
    ]
  },
  "Kraken": {
    name: "Kraken",
    priceForShare: "$44.8",
    fakePrice: "$64",
    salePercentage: "30%",
    presentationLink: "/presentations/Kraken.pdf",
    description: {
      en: "Kraken is a cryptocurrency exchange and bank, founded in 2011, one of the first bitcoin exchanges to be listed on Bloomberg Terminal and is valued at $11B, as of mid-summer 2022.",
      ja: "Krakenは、2011年に設立された暗号通貨取引所兼銀行で、Bloomberg Terminalに最初に掲載されたビットコイン取引所の1つで、2022年夏中旬現在、$11Bと評価されています。"
    },
    creativeLink: '/creative/Kraken_cover.jpg',
    nftsCreativeLinks: [
      'creative/krakenNfts/t1.png',
      'creative/krakenNfts/t2.png',
      'creative/krakenNfts/t3.png',
      'creative/krakenNfts/t4.png',
      'creative/krakenNfts/t5.png',
    ]
  },
  "Ledger": {
    name: "Ledger",
    priceForShare: "11",
    fakePrice: "$800",
    salePercentage: "0%",
    presentationLink: "/presentations/Ledger.pdf",
    isHidden: true,
    description: {
      en: "Ledger is a fast paced, growing company developing security and infrastructure solutions for cryptocurrencies as well as blockchain applications for individuals and companies, by leveraging a distinctive, proprietary technology.",
      ja: ""
    },
    creativeLink: '/creative/Ledger_cover.gif',
    nftsCreativeLinks: [
      'creative/krakenNfts/t1.png',
      'creative/krakenNfts/t2.png',
      'creative/krakenNfts/t3.png',
      'creative/krakenNfts/t4.png',
      'creative/krakenNfts/t5.png',
    ]
  },
  // "Thrasio": {
  //   name: "Thrasio",
  //   priceForShare: "$10.125",
  //   fakePrice: "$20.25",
  //   salePercentage: "50%",
  //   presentationLink: "/presentations/Thrasio.pdf",
  //   description: {
  //     en: "Thrasio is a next-generation consumer goods company reimagining how the world's most-loved products become accessible to everyone, Amazon aggregator.",
  //     ja: "Thrasioは、世界で最も愛されている製品を誰もが手に入れられるようにする方法を再構築する次世代消費財メーカーで、Amazonアグリゲーターです。"
  //   },
  //   creativeLink: '/creative/Thrasio_cover.mp4',
  //   nftsCreativeLinks: [
  //     'creative/thrasioNfts/t1.png',
  //     'creative/thrasioNfts/t2.png',
  //     'creative/thrasioNfts/t3.png',
  //     'creative/thrasioNfts/t4.png',
  //     'creative/thrasioNfts/t5.png',
  //   ]
  // },
  "Chainalysis": {
    name: "Chainalysis",
    priceForShare: "$32",
    fakePrice: "$40",
    salePercentage: "20%",
    presentationLink: "/presentations/Chainalysis.pdf",
    description: {
      en: "Chainalysis is a blockchain data and software service provider that creates tools and research for government agencies, crypto exchanges, and financial institutions.",
      ja: "Chainalysis は、政府機関、仮想通貨取引所、金融機関向けのツールと調査を作成するブロックチェーン データおよびソフトウェア サービス プロバイダーです。"
    },
    creativeLink: '/creative/Chainalysis_cover.jpg',
    nftsCreativeLinks: [
      'creative/chainalysisNfts/t1.png',
      'creative/chainalysisNfts/t2.png',
      'creative/chainalysisNfts/t3.png',
      'creative/chainalysisNfts/t4.png',
      'creative/chainalysisNfts/t5.png',
    ]
  },
  "MMPro Trust": {
    name: "MMPro Trust",
    priceForShare: "$2.86",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "",
    description: {
      en: "MMPRO Trust is a web3 and blokchain associated venture fund and broker managing $150M worth of the various companies shares such as Consensys(Metamask),Animoca Brands, Chainalysis, Bitpay. It is a unique gateway to the latest allocations and shares offers on the market.",
      ja: "MMPRO Trust は、Web3 およびブロックチェーン関連のベンチャー ファンドおよびブローカーであり、Consensys (Metamask)、Animoca Brands、Chainalies、Bitpay などのさまざまな企業の株式 1 億 5,000 万ドル相当を管理しています。 これは、市場での最新の割り当てと株式オファーへの独自のゲートウェイです。"
    },
    creativeLink: '/creative/Chainalysis_cover.jpg',
    nftsCreativeLinks: [
      'creative/chainalysisNfts/t1.png',
      'creative/chainalysisNfts/t2.png',
      'creative/chainalysisNfts/t3.png',
      'creative/chainalysisNfts/t4.png',
      'creative/chainalysisNfts/t5.png',
    ]
  },
  "MMPro Terminal": {
    name: "MMPro Terminal",
    priceForShare: "$1.43",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "",
    description: {
      en: "Market Making Pro trading terminal for centralized and decentralized exchanges is a SaaS developed by the core tech and trader team. Based on the experience over the last 5 years and around 1000 projects since early 2017. It’s a geniuine trading terminal which provides a smooth trading experience on the vast majority of centralized and decentralized crypto exchanges within one platform.",
      ja: "Market Making Pro は、集中型および分散型取引所向けの取引ターミナルで、コア技術チームとトレーダー チームによって開発された SaaS です。 過去 5 年間の経験と、2017 年初頭からの約 1000 のプロジェクトに基づいています。これは、1 つのプラットフォーム内で集中型および分散型の仮想通貨取引所の大部分でスムーズな取引体験を提供する本物の取引ターミナルです。"
    },
    creativeLink: '/creative/Chainalysis_cover.jpg',
    nftsCreativeLinks: [
      'creative/chainalysisNfts/t1.png',
      'creative/chainalysisNfts/t2.png',
      'creative/chainalysisNfts/t3.png',
      'creative/chainalysisNfts/t4.png',
      'creative/chainalysisNfts/t5.png',
    ]
  },
  "Ripple": {
    name: "Ripple",
    priceForShare: "$1.43",
    fakePrice: "",
    salePercentage: "",
    presentationLink: "",
    description: {
      en: "Ripple is a real-time gross settlement system, currency exchange and remittance network that is open to financial institutions worldwide.",
      ja: "リップルは、世界中の金融機関に公開されているリアルタイムのグロス決済システム、通貨交換および送金ネットワークです。"
    },
    creativeLink: '/creative/Chainalysis_cover.jpg',
    nftsCreativeLinks: [
      'creative/chainalysisNfts/t1.png',
      'creative/chainalysisNfts/t2.png',
      'creative/chainalysisNfts/t3.png',
      'creative/chainalysisNfts/t4.png',
      'creative/chainalysisNfts/t5.png',
    ]
  }
}
